/* eslint-disable camelcase  */
/* eslint-disable  */
/**
 *@Description tabs
 *@author meng wang
 *@date 2020/7/23
 */
import React from 'react'

export default function Tabs({ items, wrapper_class, tabExchange }) {
  return (
    <ul className={`c-nav-tabs ${wrapper_class}`}>
      {items.map((item, index) => {
        // 基础样式
        const base_classname = `link_class ${
          item.is_active ? 'is-active' : ''
        } ${item.is_disabled ? 'is-disabled' : ''}  ${
          item.link_class ? item.link_class : ''
        } `
        const link_class =
          item.link_class && item.link_class !== 'js-adm-tab'
            ? ' js-nav-tab-link'
            : ''
        const classNames = `c-nav-tabs__btn ${base_classname} ${link_class}`

        // 样式是否包含bordered
        const flag = wrapper_class.includes('c-nav-tabs--bordered')
        return (
          <li
            className={`${
              flag ? 'c-nav-tabs__menu' : 'c-nav-tabs__item item_class'
            } `}
            role={flag ? '' : 'persentation'}
            key={item.label}
            onClick={() => tabExchange(item, index)}
          >
            <a
              href={item.href}
              className={
                flag
                  ? classNames
                  : `c-nav-tabs__link js-nav-tab-link ${base_classname}`
              }
              data-toggle={item.toggle}
              aria-controls={items.controls}
              role="tab"
              data-url={item.data_url}
            >
              <div style={{ padding: "0px 10px", display: 'inline-block' }}> {item.label} </div>
              <div className="c-nav-tabs__count">{item.num ? item.num : 0}</div>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

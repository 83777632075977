/**
 *@Description btn
 *@author meng wang
 *@date 2020/7/24
 */
import React from 'react'
import Icon from './icon'
import bem from '../../utils/bem'

export default function Btn({
  sl, disabled, content, href = '#', id = '', type = 'primary', size = 'md', tag = 'a', cl = '',
  outline = false, fullWidth = false, icon = '', iconSize = 'md', round = false, shadow = false,
  target = '', dataAttrs = {}, onClick}) {
  const mods = [
    'c-btn',
    outline ? `outline--${type}` : type,
    fullWidth ? 'w100' : '',
    icon ? `with-icon--${size}` : '',
    round ? 'round' : '',
    shadow ? 'shadowed' : '',
    size,
    content,
  ]

  const btnIconClass = `c-btn--${size}__icon`
  const btnContent = icon ? (
    <>
      <Icon name={icon} size={iconSize} cl={btnIconClass} />
      {content}
    </>
  )
    : content
  const attrs = {
    className: `${bem.c_mods(mods, true)} ${cl} ${disabled && 'disabled'}`,
    href: tag === 'a' ? href : 'false',
    id,
    style: sl,
    target: target.toString(),
    disabled: disabled || false,
    onClick,
  }
  attrs.data = dataAttrs || ''
  return React.createElement(tag, attrs, btnContent)
}

/*
 * @Description:
 * @Author: shilin deng
 * @Date: 2021-07-20 15:22:03
 */
/* eslint-disable react/jsx-props-no-spreading */

/**
 *@Description input
 *@author meng wang
 *@date 2020/7/24
 */
import React from 'react'

export default function Input({
  error,
  inputClass,
  positionType,
  lg,
  ...rest
}) {
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = 'formErrorRightTop'
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (error) {
    switch (positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    const maxwidth = error.includes('https://www.linkedin.com/in/johndoe/')
    errorContentField = (
      <div className={`formErrorContent ${maxwidth ? 'w3' : ''}`}>{error}</div>
    )
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map(
      (option) => <div key={option} className={`line${option}`} />,
    )
  }
  return (
    <div className={inputClass}>
      <div className="c-form-control">
        <input
          {...rest}
          className={`c-form-control__field c-form-field c-form-field--primary ${
            lg ? '' : 'c-form-field--lg'
          }`}
        />
        <div
          className={`form-validation-field-0formError parentFormundefined formError greenPopup ${position}`}
        >
          {!errorPositionIsBottom && errorContentField}
          <div className="formErrorArrow">{errorArrowField}</div>
          {errorPositionIsBottom && errorContentField}
        </div>
      </div>
    </div>
  )
}

/* eslint-disable react/jsx-props-no-spreading */

/**
 *@Description error
 *@author meng wang
 *@date 2020/9/10
 */
import React from 'react'

export default function Error({error, top = -34, positionType = 'rightTop'}) {
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = ''
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (error) {
    switch (positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    errorContentField = (<div className="formErrorContent">{error}</div>)
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map((option) => (
      <div className={`line${option}`} key={option} />
    ))
  }
  return (
    <div style={{top: `${top}px`}} className={`form-validation-field-0formError formError greenPopup ${position}`}>
      {!errorPositionIsBottom && errorContentField}
      <div className="formErrorArrow">
        {errorArrowField}
      </div>
      {errorPositionIsBottom && errorContentField}
    </div>
  )
}

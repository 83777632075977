/*
 * @Description: Preview
 * @Author: shilin deng
 * @Date: 2022-03-23 09:53:18
 */
/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Modal from './modal'
import Btn from './btn'

const DynamicLazyComponent = dynamic(() => import('./math'), {
  ssr: false,
})

export default function Preview({ close, id }) {
  function cancel(e) {
    e.preventDefault()
    close()
  }
  return (
    <Modal close={close} width="80%">
      <Head>
        <script
          async
          src="https://polyfill.io/v3/polyfill.min.js?features=es6"
        ></script>
        <script
          async
          src="https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/mathjax/tex-mml-svg.js"
        ></script>
      </Head>
      <h1 className="c-modal__title u-mb-m">Preview</h1>
      <DynamicLazyComponent math={tinyMCE.get(id).getContent()} />
      <Btn type="alt" cl="right" content="Close" onClick={cancel} />
    </Modal>
  )
}

// /* eslint-disable */
/**
 * @Description  ProfileImage
 * @author shilin deng
 * @date 2020/12/3
 */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
import React from 'react'

export default function ProfileImage({
  initials = undefined,
  image = undefined,
  alt = undefined,
  wrapper_class = '',
  data_original = '',
  img_class_name = '',
  label = '',
  onerror = () => {},
}) {
  return (
    <div className={`c-avatar ${wrapper_class}`} data={{ initials }}>
      {image ? (
        <img
          alt={alt}
          className={`c-avatar__img ${img_class_name}`}
          src={image}
          onError={onerror}
          data={{ original: data_original }}
        />
      ) : (
        <label>{label}</label>
      )}
    </div>
  )
}

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

/**
 *@Description pagination
 *@author meng wang
 *@date 2020/7/28
 */
import React, { useState, useEffect } from 'react'

export default function Pagination({current = 1, total = 0, pageChange}) {
  const [currentPage, setCurrentPage] = useState(current)

  // 获取分页列表
  function getList(cur, pageTotal) {
    if (pageTotal < 6) return Array.from(new Array(pageTotal + 1).keys()).slice(cur)
    let list
    if (cur - 3 <= 0) {
      list = Array.from(new Array(cur + 1).keys()).slice(1)
    } else {
      list = [1, '...', cur - 2, cur - 1, cur]
    }
    if (cur + 3 >= pageTotal) {
      list = list.concat(Array.from(new Array(pageTotal + 1).keys()).slice(cur + 1))
    } else {
      list = list.concat(cur + 1, cur + 2, '...', pageTotal)
    }
    return list
  }

  const [pageList, setPageList] = useState(getList(currentPage, total))

  // 监听当前页变化
  useEffect(() => {
    setPageList(getList(currentPage, total))
  }, [currentPage])

  // 监听props变化
  useEffect(() => {
    setCurrentPage(current)
    setPageList(getList(currentPage, total))
  }, [current, total])

  function pageClick(e) {
    if (!e.target.parentNode.className.includes('disabled')) {
      let num
      if (e.target.innerText === '«') {
        num = currentPage - 1
      } else if (e.target.innerText === '»') {
        num = currentPage + 1
      } else {
        num = Number(e.target.innerText)
      }
      setCurrentPage(num)
      pageChange(num || 1)
    }
  }

  return (
    <ul className="pagination__list" onClick={pageClick}>
      <li className={currentPage === 1 ? 'pagination__list__disabled' : ''}><a>«</a></li>
      {
        pageList.map((page, index) => (
          <li key={`page-item-${index}`} className={page === currentPage ? 'pagination__list__disabled' : ''}><a>{page}</a></li>
        ))
      }
      <li className={currentPage === total ? 'pagination__list__disabled' : ''}><a>»</a></li>
    </ul>
  )
}

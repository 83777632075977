/*eslint-disable*/
import _ from 'lodash'
import React, { PureComponent } from 'react'
import * as Feather from 'react-feather'
import { SVGInjector } from '@tanem/svg-injector'
import BEM from '../../utils/bem'

class Icon extends PureComponent {
  constructor(props) {
    _.defaults(props, {notification: 0})
    super(props)
  }

  componentDidMount() {
    const {type} = this.props
    if (type === 'oc') {
      SVGInjector(this.container.querySelectorAll('.js-svg-inject'))
    }
  }

  render() {
    const {id, type, name, cl, svgClass, size, color, inline, notification,
      onMouseEnter, onMouseLeave} = this.props
    const sizeArr = size && size.split(' ')
    const colorArr = color && color.split(' ')
    const inlineMod = inline ? 'inline' : null
    const modsClass = BEM.c_mods(['c-icon', sizeArr, colorArr, inlineMod], true)
    let notificationTag
    let icon;
    if (notification && parseInt(notification, 10) > 0) {
      notificationTag = <span className="c-message-counter--sticked c-message-counter">{notification}</span>
    }
    if (type === 'oc') {
      icon = <span className="c-icon__svg js-svg-inject" data-src={name} />
    } else {
      const FeatherTag = Feather[_.upperFirst(_.camelCase(name))]
      icon = <FeatherTag className={`c-icon__svg feather feather-${name} ${svgClass}`} />
    }
    return (
      <span
        className={`${modsClass} ${cl}`}
        id={id}
        ref={(el) => { this.container = el }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {notificationTag || ''}
        {icon}
      </span>
    )
  }
}

export default Icon

/*eslint-disable */
/**
 *@Description 常量
 *@author meng wang,shilin Deng
 *@date 2020/7/29
 */

const USER_PHONE_CODE_SELECT = [
  ['中国 +86', '中国 +86'],
  ['加拿大 +1', '加拿大 +1'],
  ['美国 +1', '美国 +1'],
  ['澳洲 +61', '澳洲 +61'],
  ['新西兰 +64', '新西兰 +64'],
  ['英国 +44', '英国 +44'],
]

const TUTOR_PHONE_CODE_OPTIONS = [
  ['US(+1)', '1'],
  ['CA(+1)', '1'],
  ['AUS(+61)', '61'],
  ['NZ(+64)', '64'],
  ['UK(+44)', '44'],
  ['CN(+86)', '86'],
]
const USER_COUNTRY = [
  ['美国', 'us', 2],
  ['加拿大', 'ca', 1],
  ['澳洲', 'au', 3],
  ['英国', 'uk', 5],
]

const QUESTION_TYPES = {
  life: 1,
  expert: 2,
  essay: 3,
  email: 4,
}
// 选课常见误区
const COURSE_STAGES = [
  {
    id: '01',
    border: 'ltst',
    title: '无视学校要求',
    text: '海外大学的课程会根据学校要求分成必修、选修、前导课等多个类别，然而不同学校、不同专业对所修课程的数量与时间要求也不尽相同。许多学生由于不了解获取信息的渠道，或者自认为已经非常了解学校要求而忽略了在官方网站查找信息这一步骤，导致不能按时毕业等严重后果。',
  },
  {
    id: '02',
    border: 'ltr',
    title: '专有名词误读',
    text: 'Required、Compulsory、Pre-requisite、Withdraw这些在选课过程中必须要面对的英语词汇的准确意思分别是什么？中国学生普遍对必修课与选修课较为熟悉，而对其它名词鲜有了解。许多学生直接忽略了这些专有词汇，甚至有的同学会按照自己的想象为它们匹配错误的中文解释，导致在毕业时发现自己由于没有达到毕业要求而不能按时毕业。',
  },
  {
    id: '03',
    border: 'lt',
    title: '忽略授课教授风格',
    text: '海外大学的教授并不像国内的老师一样，将培养学生作为主要目标，在学校全职工作。教授们往往身兼数职，没有时间处理学生个性化的问题。教授们负责自己所教授的学科，在课程内容和考核方式的设定上享有自主权。同时，海外大学中的教授以多样化著称，口音各异，文化背景各不相同，于是就出现了同一门课的不同教授会带来完全不同的学习体验这一情况。有些教授倾向布置写作类型的作业，有些教授不安排期中期末的考试，甚至有的教授会在每节课中都插入随堂考试。如果选择了教学风格不适合自己的教授，未来的学习将会难上加难。',
  },
  {
    id: '04',
    border: 'dk',
    title: '盲目自信',
    text: '面对即将到来的大学生活，许多同学都对未来自信满满。然而，高中阶段的学习要求与海外大学的学业情况并不匹配，一学期学习7、8门课已经不再现实。由于不了解自己即将面对的高强度学习，也没有为处理生活琐事的时间做出预估，许多同学会因没有适量选课，最终导致学习跟不上，甚至陷入学业危机。',
  },
  {
    id: '05',
    border: 'dkr',
    title: '望文生义',
    text: '除物理、化学、数学等基础课程外，海外大学中的许多课程都有一个响亮的名字，但是通过名字却不能准确判断该门课程的具体内容。面对上百门可以选择的课程，许多同学仅按照自己对课程名字的喜好直接选课，导致真正上课时对课程内容不感兴趣或者发现自己选择了难度较大的课程。',
  },
  {
    id: '06',
    border: 'dkst',
    title: '随波逐流',
    text: '不知如何查找课程信息，或自己不想费心查找信息，这在留学生身上较为常见。于是，学生们选择结伴选课，跟随其他同学脚步，选择和身边朋友一样的课程。这样的选课方式忽视了学生自身的特点和兴趣，或者选到了教学方式不适合自己的教授，难以拿到高分。有的同学甚至会选择与学校毕业要求不相符的课程，导致不能按时毕业。',
  },
]
// 合理选课建议
const COURSE_SUGGESTION = [
  {
    id: '1',
    title: '查看官方信息或权威信息源',
    text: '随着社交媒体的发展，越来越多的平台可以为学生提供留学所需的种种信息。然而，信息的准确性与可观性并不能得到保障，这导致许多留学生错信他人，造成严重后果。因此，学校官方网站与官方机构发布的信息才是首要信息源，应得到足够的重视。',
  },
  {
    id: '2',
    title: '熟读课程描述',
    text: '课程描述是一份来自学校的、针对课程内容、学分、教学目的等信息进行描述的官方材料，多见于学校官网，是学生了解学校课程的有效途径。但是，学校的官网往往信息繁杂，不同学校会将课程描述放在不同的目录下，需要花费一定的时间和精力才能准确找到。',
  },
  {
    id: '3',
    title: '分清必修与选修课',
    text: '海外大学是学分制，也就是说，什么时候修满了学分就可以毕业。在网络选课系统中，会有Calender详细显示着你需要学习的必修和选修的科目要求，要多花时间研究需要学习的科目，这点很重要，一定要看清毕业的各项要求。',
  },
  {
    id: '4',
    title: '在课程数量与难度上量力而行',
    text: '所有课程分为四个level或者更多。第一个level最简单，最后一个level最难。对自己的大学生涯提前有一个长期规划，分清主次课程尤为必要。有些课程只会在一年中的某一个学期开课，你要抓紧在这个特殊的学期修掉，否则会等上数个学期或者一整年，从而影响毕业时间。前导课要尽量安排在先，否则无法修后面的课程。',
  },
  {
    id: '5',
    title: '关注授课教授的授课风格与考核方式',
    text: '一般来说，海外大学中，一门课会有不同的教授来授课，每个教授的自由度很高，授课方式和评分标准与结构不同，同一位学生在不同的教授那里取得的成绩可能有非常大的区别。因此，选择适合自己的教授对顺利毕业来说至关重要。',
  },
]

// 荃程无忧选课指导
const COURSE_FLOW = [
  // {
  //   src: '/images/pages/course-select/flow1.svg',
  //   flag: true,
  //   text: 'Gallup个人优势测试',
  // },
  {
    src: '/images/pages/course-select/flow2.svg',
    text: '了解学校与所在专业的毕业要求',
    reverse: true,
  },
  {
    src: '/images/pages/course-select/flow3.svg',
    text: '了解学生信息',
  },
  {
    src: '/images/pages/course-select/flow4.svg',
    text: '合理、科学制作 <a href="#flow">选课报告</a>',
    reverse: true,
  },
  {
    src: '/images/pages/course-select/flow5.svg',
    text: '学生发展导师讲解选课报告',
  },
  {
    src: '/images/pages/course-select/flow6.svg',
    text: '根据学生反馈调整并确定最终的选课方案',
    reverse: true,
  },
  {
    src: '/images/pages/course-select/flow7.svg',
    text: '提醒学生选课',
  },
  {
    src: '/images/pages/course-select/flow8.svg',
    text: '协助学生进入选课系统，完成选课',
    reverse: true,
  },
]

// 论文导师
const HOMEWORK_TUTORS = [
  {
    src: '/images/pages/homework/kevin.png',
    name: 'Kevin John Britton',
    major: '统计学',
    degree: 'Master',
    order: 'order-md-1',
  },
  {
    src: '/images/pages/homework/stephen.png',
    name: 'Stephen Pastore',
    major: '物理学',
    degree: 'PhD',
    order: 'order-md-2',
  },
  {
    src: '/images/pages/homework/alicia.png',
    name: 'Alicia Sinclair',
    major: '金融学',
    degree: 'Master',
    order: 'order-md-3',
  },
  {
    src: '/images/pages/homework/david.png',
    name: 'David Milman',
    major: '管理学',
    degree: 'Master',
    order: 'order-md-4',
  },
  {
    src: '/images/pages/homework/shannon.png',
    name: 'Shannon Thompson',
    major: '经济学',
    degree: 'Master',
    order: 'order-md-6',
  },
  {
    src: '/images/pages/homework/stacy.png',
    name: 'Stacy Costa',
    major: '工程学',
    degree: 'PhD',
    order: 'order-md-7',
  },
  {
    src: '/images/pages/homework/matt.png',
    name: 'Matthew Prout',
    major: '计算机科学',
    degree: 'Master',
    order: 'order-md-8',
  },
]

const LT__CHALLENGES = [
  {
    type: 'student',
    text: '海外大学课堂学生人数众多，学生难以受到教授足够的关注',
  },
  {
    type: 'hour',
    text: 'Office hour 时间有限，无法个性化解决每个学生的学术困惑',
  },
  {
    type: 'book',
    text: '随着课程推进，作业、测试等逐渐增多，学术问题持续堆积',
  },
  {
    type: 'write',
    text: '作业、论文及考试的GPA 占比较高，一项成绩低直接影响最终GPA',
  },
  {
    type: 'plan',
    text: '学生缺乏长期的学业规划，成绩难以实现长期稳步提升',
  },
]
const LT__INFO = [
  {
    name: 'Shan L',
    avatar: 'shan',
    degree:
      '加州大学圣地亚哥分校 生理学和神经科学 & 心理学 双学士学位 | 哥伦比亚大学 生物科学 博士在读',
    bio: '本科期间开始参与科研项目，多年实验室研究经验。曾担任生物课助教，教学经验丰富，讲解清晰，深受学生欢迎',
    img: 'bio',
    course: '生物学视频辅导课程',
    course_info: '帮助学生逐一讲解生物学习题，进行题干分析以及每个选项的解析。',
  },
  {
    name: 'Jing',
    avatar: null,
    degree:
      '麻省理工学院 物理学硕士 数学博士在读 | 剑桥大学 自然科学学士 物理学硕士',
    bio: '曾在哈佛大学、加州理工学院、麻省理工等多所名校开展研究，专业知识扎实。',
    img: 'eco',
    course: '经济学视频辅导课程',
    course_info:
      '协助学生复习上课资料，理解开放经济中的重要概念，以及区分不同成本之间的差异。',
  },
  {
    name: 'Levi B',
    avatar: 'levi',
    degree: '英国皇后大学 物理学 学士 | 英属哥伦比亚大学 物理学 硕士',
    bio: '精通物理、数学、计算机等专业知识。五年以上物理及数学教学经验。共计辅导过700多名学生。',
    img: 'mat',
    course: '数学视频辅导课程',
    course_info: '帮助学生解决了一系列数学难题，并协助学生进行期中考试复习。',
  },
  {
    name: 'Allen Z',
    avatar: 'allen',
    degree: '达特茅斯学院 学士 | 加州大学洛杉矶分校 英语 硕士&博士',
    bio: '精通写作，六年文学和写作课程的教学经验，擅长帮助学生掌握第二门语言-英语。',
    img: 'eng',
    course: '英语视频辅导课程',
    course_info: '为学生进行雅思口语考前复习，帮助学生掌握每一部分的答题重点。',
  },
]
const LT__TYPES = [
  {
    id: '1',
    title: '预习课程',
    sub: '主动学习养成/专项课程预习',
    text: '离境前，学生可以通过预约一对一的预习课程，初步了解和掌握海外大学专业课程知识，加速适应海外学习环境。',
  },
  {
    id: '2',
    title: '学术答疑',
    sub: '个性化教学方案/重点难点突破',
    text: '每次学生可预约1-2节课程，针对考试和日常作业，精准攻克学术难题，获得知识点详尽解析及清晰的解题思路。',
  },
  {
    id: '3',
    title: '学科强化',
    sub: '长期课程规划/知识全面梳理',
    text: '每周为学生定制具体的辅导课程；针对学生的薄弱科目进行长期持续性辅导，集中火力，优化时间，补足短板。',
  },
  {
    id: '4',
    title: '论文辅导',
    sub: '专业科学指导/精准强化提升',
    text: '专注于学生的论文写作技能提升，有针对性地进行辅导，实现个性化从零到一的论文指导。',
  },
  {
    id: '5',
    title: '考前冲刺',
    sub: '考前直击重点/作答技巧传授',
    text: '每一次备考期间，学生均可通过考前冲刺，攻克考试重点难点，实现精准复习，更重要的是获得专业巧妙的解答技巧。',
  },
]

const LT__STEPS = [
  {
    id: '1',
    text: "精心挑选并邀请高质量的优秀<a href='/homework-help-na'>学术问答</a>导师加入视频辅导课程团队",
  },
  {
    id: '2',
    text: '候选导师需完成系统的视频辅导课程培训与测试',
  },
  {
    id: '3',
    text: '通过测试的候选导师需要认真准备并完成10分钟的演示课程',
  },
  {
    id: '4',
    text: '成功完成的优秀导师被录用',
  },
]

const LT__TUTORS = [
  {
    name: 'Sebastian G',
    avatar: 'seb',
    degree:
      "<b>普林斯顿大学</b><span class='u-color-dark'> | 机械和航天工程专业与物理学 双学位</span><br><b>斯坦福大学</b><span class='u-color-dark'> | 航空航天系 博士在读</span>",
    bio: '在计算力学，高性能计算、计算和应用数学等领域研究经验丰富',
  },
  {
    name: 'Daisy S',
    avatar: 'daisy',
    degree:
      "<b>多伦多大学</b><span class='u-color-dark'> | 大众传媒 学士</span><br><b>多伦多大学</b><span class='u-color-dark'> | 安大略教育研究所 硕士</span>",
    bio: '五年以上ESL教学经验，擅长教授英语论文和英语语言测试准备',
  },
  {
    name: 'Tudor CP',
    avatar: 'tudor',
    degree:
      "<b>麻省理工学院</b><span class='u-color-dark'> | 物理学硕士 数学博士在读</span><br><b>剑桥大学</b><span class='u-color-dark'> | 自然科学学士 物理学硕士</span>",
    bio: '曾在哈佛大学、加州理工学院、麻省理工等多所名校开展研究，专业知识扎实',
  },
  {
    name: 'Joe J',
    avatar: 'joe',
    degree:
      "<b>芝加哥大学</b><span class='u-color-dark'> | 数学专业 博士</span>",
    bio: '曾在纽约大学、哥伦比亚大学、波士顿学院等任教，拥有15年教学经验，擅长辅导数学和物理',
  },
  {
    name: 'Levi B',
    avatar: 'levi',
    degree:
      "<b>英国皇后大学</b><span class='u-color-dark'> | 物理学 学士</span><br><b>英属哥伦比亚大学</b><span class='u-color-dark'> | 物理学 硕士</span>",
    bio: '精通物理、数学、计算机等专业知识，五年以上物理及数学教学经验，共计辅导过700多名学生',
  },
  {
    name: 'Nina T',
    avatar: 'nina',
    degree:
      "<b>多伦多大学</b><span class='u-color-dark'> | 电子计算机工程 学士</span><br><b>斯坦福大学</b><span class='u-color-dark'> | 计算机专业 硕士</span>",
    bio: '现为Google软件工程师，教学辅导经验丰富，擅长与学生沟通交流',
  },
]

// 选择荃程无忧五大原因
const WHY_REASONS = [
  {
    id: '01',
    title: '缩短适应周期',
    text: '在海外留学的课业压力十分繁重，几乎每周都要面对作业、考试和论文，留学生如果不能在短时间内适应高强度的学业压力，则严重影响最终成绩（GPA），延迟毕业甚至被学校退学的情况比比皆是。<br><br>荃程无忧从选课开始，为留学生提供全套学习辅助服务，使留学生迅速适应高强度、高压力的海外学习，缩短适应周期。',
  },
  {
    id: '02',
    title: '海外菁英导师',
    text: '荃程无忧汇聚了全球英语国家各院校的千余位精英导师，这些来自于美国常春藤以及其它美国Top50名校、加拿大G13、澳大利亚G8、英国G5的硕士、博士及教职人员，均经过荃程无忧的严格筛选和培训，具备丰富的经验，帮助学生解决各个科目的多种问题。',
  },
  {
    id: '03',
    title: '顶尖教育资源',
    text: '多年的实践，使得荃程无忧积累了海量优质的学习资料以及专业课程要点。荃程无忧囊括了丰富的海外学术资源，涉及教辅资料、学霸笔记、精英导师团队等多个方面，不仅为学生提供全方位的学业辅导计划，还通过发展指导服务为其实现成功人生奠定基石。',
  },
  {
    id: '04',
    title: '国际教育的全球化布局',
    text: '自2010年荃程无忧北美总部成立至今，服务了超过300万名学生，覆盖美国、加拿大、澳洲800多所大学，服务范围广泛。多年的海外运营和市场深耕，逐步铸造了荃程无忧成熟的商业模式，确保高质量的服务。',
  },
]

// 关于我们 主要顾问
const ABOUT_PARTNERS = [
  {
    src: '/images/pages/about/ymh.jpg',
    title: '俞敏洪',
    text: '新东方教育集团创始人，英语教学与管理专家。担任新东方教育集团董事长、洪泰基金联合创始人、中国青年企业家协会副会长、中华全国青年联合会委员等职。',
  },
  {
    src: '/images/pages/about/hx.jpg',
    title: '黄娴',
    text: '启德教育CEO，黄娴女士拥有超过20年的教育行业工作经验，曾任职于毕马威公司、大众集团、培生教育（亚洲）集团。',
  },
  {
    src: '/images/pages/about/yan.jpg',
    title: '阎焱',
    text: '现任赛富亚州投资基金创始管理合伙人，曾任软银亚洲投资基金首席合伙人、软银亚洲信息基础投资基金总裁、AIG亚洲基础设施投资基金之董事总经理及香港办主任、Sprint International Corporation亚太区战略规划及业务发展董事。',
  },
  {
    src: '/images/pages/about/chiyanming.jpg',
    title: '池燕明',
    text: '现任北京立思辰科技股份有限公司董事长。于1999年成立立思辰有限公司，任执行董事、经理，后兼任立思辰信息技术公司经理、立思辰新技术公司经理。在此之前，曾担任北京当代电子经营部副经理、北京东方办公设备有限公司总经理。',
  },
  {
    src: '/images/pages/about/al.jpg',
    title: 'Alex Liang',
    text: 'Oriza Ventures（元禾谷风创投）创始合伙人，曾任赛富基金合伙人，也是中国最早的移动互联网公司之一PICA的创始人和首席执行官。',
  },
  {
    src: '/images/pages/about/js.jpg',
    title: 'John Stokes',
    text: 'John Stokes先生是风险投资集团Real Ventures的投资合伙人。Stokes先生曾创立多家软件、网络、媒体、及投资顾问公司。',
  },
  {
    src: '/images/pages/about/dc.jpg',
    title: 'David Crow',
    text: 'David Crow先生是加拿大风投机构OMERS Ventures的业务总监，也是B2B宣传营销公司Influitive的创始人。',
  },
]
// 关于我们 教育专家
const ABOUT_EDUCATORS = [
  {
    src: '/images/pages/about/rich.jpg',
    title: 'Richard DeCapua博士',
    subtitle: '波士顿学院<br>教务副院长',
    text: '高等教育专业人士，作为管理员和教职员工，在该领域有超过18年的教育经验，并与众多机构的广大学生群体合作。',
  },
  {
    src: '/images/pages/about/tara.jpg',
    title: 'Tara Bartley',
    subtitle: '哈佛大学<br>高级项目主管',
    text: 'Tara拥有梅里马克学院的政治学学士学位并在波士顿学院获得高等教育硕士学位，就读期间专注于波士顿学院的学生事务；现任哈佛大学高级项目主管以及研究生公共项目主管一职。',
  },
  {
    src: '/images/pages/about/carole.jpg',
    title: 'Carole Hughes博士',
    subtitle: '波士顿学院<br>高级副院长/研究生生活主任',
    text: '目前是波士顿学院学生事务部高级副院长，她的职务包括对行为系统，校外学生关系，研究生生活办公室以及大学职业中心的监督。',
  },
  {
    src: '/images/pages/about/austin.jpg',
    title: 'Austin Ashe博士',
    subtitle: '麻省理工学院<br>校友关系发展部副主任',
    text: '拥有学生事务及校友资源开发经验的高等教育专业人士， 在管理大型课程和计划方面有丰富的经验，同时还负责组织新生入学指导，大学校友聚会，学生务虚会，多元化/包容性计划，以及关于促进社会和种族正义的研讨会。',
  },
  {
    src: '/images/pages/about/hannah.jpg',
    title: 'Hannah Trost',
    subtitle: '波士顿学院<br>研究生生活副主任',
    text: '拥有芝加哥洛约拉大学市场学学位并在波士顿学院获得高等教育硕士学位；现任波士顿学院研究生生活中心副主任一职。',
  },
]
// 关于我们 主要投资人
const ABOUT_INVERTORS = [
  {
    src: '/images/pages/about/saif.png',
    title: '赛富基金',
    text: '赛富基金是亚洲最大的风险投资和成长期企业投资基金之一，成立于2001年，目前总管理约40亿美元的基金。',
  },
  {
    src: '/images/pages/about/rv.png',
    title: 'Real Ventures',
    text: 'Real Ventures是加拿大最大的风险投资集团之一，成立于2007年，目前总管理约1亿加币的基金。',
  },
  {
    src: '/images/pages/about/pga.jpg',
    title: 'PGA',
    text: 'PGA (Penta Global Advisers Fund) 由Penta 集团董事长John Zwaanstra 先生创建，投资范围主要分布在金融、房地产、医疗、教育、文化、制造业、消费等多元化领域。旗下拥有多支基金，分别投资在亚洲地区的VC投资、PE投资以及证券市场。',
  },
  {
    src: '/images/pages/about/oriza.jpg',
    title: 'Oriza Ventures',
    text: 'Oriza Ventures是一家风险投资基金，主要投资于美国早期的技术公司。Oriza Venture通过新兴的、创新的、颠覆性的技术追求和创造性的商业理念来支持有才华的企业家。',
  },
  {
    src: '/images/pages/about/harry.png',
    title: 'Harry Patz',
    text: 'Harry Patz先生曾任职微软北美传媒副总裁，以及移动广告公司Velti的营收总监。Patz先生现为一名天使投资人并创办了Gondolin Advisors顾问公司。',
  },
  {
    src: '/images/pages/about/bill.png',
    title: 'Bill Zanker',
    text: 'Bill Zanker先生是北美最大的继续教育机构The Learning Annex公司的创始人兼董事长，以及《创：美国商界巨子特朗普的商业法则》的作者之一。',
  },
  {
    src: '/images/pages/about/jamie.png',
    title: "Jamie O'Hara",
    text: "Jamie O'Hara先生是Grise Global Groupe投资公司的创始人兼董事总经理。",
  },
]
// 亚太地区团队
const TEAM_MEMBERS_CN = [
  { src: '/images/pages/team/cn/jackey-2.jpg' },
  { src: '/images/pages/team/cn/frank-2.jpg' },
  { src: '/images/pages/team/cn/tommy-2.jpg' },
  { src: '/images/pages/team/cn/raymond-2.jpg' },
  // { src: '/images/pages/team/cn/james-2.jpg' },
  { src: '/images/pages/team/cn/nancy.jpg' },
  { src: '/images/pages/team/cn/wenwei.jpg' },
  { src: '/images/pages/team/cn/nathan.jpg' },
  { src: '/images/pages/team/cn/niejie.jpg' },
  { src: '/images/pages/team/cn/becky.jpg' },
  // {src: '/images/pages/team/cn/alex_1.jpg'},
  { src: '/images/pages/team/cn/renhongli.jpg' },
  { src: '/images/pages/team/cn/nuoyan.jpg' },
  { src: '/images/pages/team/cn/guowen.jpg' },
  { src: '/images/pages/team/cn/koujie.jpg' },
  { src: '/images/pages/team/cn/lilei.jpg' },
  // {src: '/images/pages/team/cn/bruce.jpg'},
  { src: '/images/pages/team/cn/sanny.jpg' },
  { src: '/images/pages/team/cn/aubrey.jpg' },
  // {src: '/images/pages/team/cn/yutian.jpg'},
  // {src: '/images/pages/team/cn/louis.jpg'},
  { src: '/images/pages/team/cn/etta.jpg' },
  { src: '/images/pages/team/cn/jason.jpg' },
  // {src: '/images/pages/team/cn/fiona.jpg'},
  { src: '/images/pages/team/cn/eunice.jpg' },
  { src: '/images/pages/team/cn/chilia.jpg' },
  { src: '/images/pages/team/cn/rebecca.jpg' },
  { src: '/images/pages/team/cn/serena.jpg' },
  { src: '/images/pages/team/cn/max.jpg' },
  { src: '/images/pages/team/cn/laura.jpg' },
  // {src: '/images/pages/team/cn/lin.jpg'},
  // {src: '/images/pages/team/cn/donglai.jpg'},

  // { src: '/images/pages/team/cn/logan.jpg' },
  // { src: '/images/pages/team/cn/zhangyang.jpg' },
  { src: '/images/pages/team/cn/lynn.jpg' },
  { src: '/images/pages/team/cn/yangyuqi.jpg' },
  { src: '/images/pages/team/cn/liaoling.jpg' },
  // {src: '/images/pages/team/cn/maruohan.jpg'},
  { src: '/images/pages/team/cn/wangchenxi.jpg' },
  // {src: '/images/pages/team/cn/zhaoruixin.jpg'},
  // { src: '/images/pages/team/cn/zhouanduoduo.jpg' },
  // { src: '/images/pages/team/cn/juqingling.jpg' },
  { src: '/images/pages/team/cn/fiesta.jpg' },
  // {src: '/images/pages/team/cn/zhucheng.jpg'},
  // {src: '/images/pages/team/cn/lucas.jpg'},
  { src: '/images/pages/team/cn/huanganqi.jpg' },
  // {src: '/images/pages/team/cn/juqingling.jpg'},
  { src: '/images/pages/team/cn/terry.jpg' },
  { src: '/images/pages/team/cn/alex.jpg' },
  // {src: '/images/pages/team/cn/duhangwei.jpg'},

  { src: '/images/pages/team/cn/olivia.jpg' },
  { src: '/images/pages/team/cn/cailu.jpg' },
  // {src: '/images/pages/team/cn/puzhenhau.jpg'},
  // {src: '/images/pages/team/cn/sunshiqi.jpg'},
  { src: '/images/pages/team/cn/jinyike.jpg' },
  // {src: '/images/pages/team/cn/qiaoqiaoying.jpg'},
  { src: '/images/pages/team/cn/fanfan.jpg' },
  // {src: '/images/pages/team/cn/jamie.jpg'},
  // {src: '/images/pages/team/cn/nicole.jpg'},
  // {src: '/images/pages/team/cn/gonglinjian.jpg'},
  { src: '/images/pages/team/cn/chenxiaojing.jpg' },
  // {src: '/images/pages/team/cn/maggie.jpg'},
  // {src: '/images/pages/team/cn/peiyu.jpg'},
  // {src: '/images/pages/team/cn/michelle.jpg'},
  { src: '/images/pages/team/cn/feiqingxuan.jpg' },
  { src: '/images/pages/team/cn/matthew.jpg' },
  { src: '/images/pages/team/cn/xiao.jpg' },
  // {src: '/images/pages/team/cn/dean.jpg'},
  // {src: '/images/pages/team/cn/jupiter.jpg'},
  { src: '/images/pages/team/cn/cnengting.jpg' },
]
// 荃程无忧教育亚太地区合伙人
const TEAM_VP = [
  {
    src: '/images/pages/team/cn/frank.jpg',
    title: 'Frank Cui',
  },
  {
    src: '/images/pages/team/cn/tommy.jpg',
    title: 'Tommy Che',
  },
  {
    src: '/images/pages/team/cn/raymond.jpg',
    title: 'Raymond Liu',
  },
]
// 北美地区团队
const TEAM_MEMBERS_NA = [
  { src: '/images/pages/team/na/jack.jpg' },
  { src: '/images/pages/team/na/maggie.jpg' },
  { src: '/images/pages/team/na/kevin.jpg' },
  { src: '/images/pages/team/na/max.jpg' },
  { src: '/images/pages/team/na/katherine.jpg' },
  { src: '/images/pages/team/na/rushdi.jpg' },
  { src: '/images/pages/team/na/susan.jpg' },
  // { src: '/images/pages/team/na/matt.jpg' },
  { src: '/images/pages/team/na/nero.jpg' },
  { src: '/images/pages/team/na/noel.jpg' },
  { src: '/images/pages/team/na/eldar.jpg' },
  // { src: '/images/pages/team/na/kevin-yan.jpg' },
  { src: '/images/pages/team/na/denis.jpg' },
  // { src: '/images/pages/team/na/gavin.jpg' },
  { src: '/images/pages/team/na/vanessa.jpg' },
  { src: '/images/pages/team/na/chelsea.jpg' },
  { src: '/images/pages/team/na/elaine.jpg' },
  // { src: '/images/pages/team/na/nicole.jpg' },
  // { src: '/images/pages/team/na/betty.jpg' },
  // { src: '/images/pages/team/na/samuel.jpg' },
  { src: '/images/pages/team/na/jing.jpg' },
  { src: '/images/pages/team/na/john.jpg' },
  { src: '/images/pages/team/na/ginny.jpg' },
  // { src: '/images/pages/team/na/babara.jpg' },
  { src: '/images/pages/team/na/niki.jpg' },
]

const TEAM_FOUNDERS = [
  {
    src: '/images/pages/team/na/jack-lg.jpg',
    title: 'Jack Tai',
    text: '荃程无忧教育集团创始人',
  },
  {
    src: '/images/pages/team/na/jackey-lg.jpg',
    title: 'Jackey Li',
    text: '荃程无忧教育集团创始人',
  },
  {
    src: '/images/pages/team/na/kevin-lg.jpg',
    title: 'Kevin Wu',
    text: '荃程无忧教育集团创始人',
  },
  {
    src: '/images/pages/team/na/maggie-lg.jpg',
    title: 'Maggie Peng',
    text: '荃程无忧教育集团创始人',
  },
]
// 联系我们
const CONTACTS = [
  {
    city: '北京',
    address: '北京市朝阳区建国路93号院建国路93号院万达广场8号楼1705',
    phone: '010-53672926',
  },
  {
    city: '上海',
    address: '上海市黄浦区延安东路222号外滩中心31楼3116',
    phone: '',
  },
  {
    city: '广州',
    address: '广州市天河区珠江东路28号越秀金融大厦66楼66-110',
    phone: '',
  },
  {
    city: '深圳',
    address: '深圳市福田区车公庙天安创新科技广场（二期）西座1003-D29',
    phone: '',
  },
  {
    city: '成都',
    address: '成都市武侯区武青南路40号见兴里科创中心A座702室',
    phone: '',
  },
]

const ISS_CARD = [
  {
    img: 'chris-scanlon',
    name: 'Chris Scanlon',
    school: '哈佛大学',
    title: '大一学生常驻院长',
    en: 'Resident Dean of First Year Students',
  },
  {
    img: 'austin-ashe',
    name: 'Austin Ashe',
    school: '麻省理工学院',
    title: '本科教育助理主任',
    en: 'Assistant Director of Undergraduate Education',
  },
  {
    img: 'susan-whipple',
    name: 'Susan Whipple',
    school: '马凯特大学',
    title: '国际教育助理主任',
    en: 'Assistant Director of International Education',
  },
  {
    img: 'dulce-dorado',
    name: 'Dulce Dorado',
    school: '加州大学圣地亚哥分校',
    title: '国际教育中心国际学生与项目主任',
    en: 'Director of International Students & Programs',
  },
  {
    img: 'susannah-marcucci',
    name: 'Susannah Marcucci',
    school: '克拉克大学',
    title: '国际学生主任',
    en: 'Director of International Student Services',
  },
  {
    img: 'sarah-farkas',
    name: 'Sarah Farkas',
    school: '东北大学',
    title: '学术与学生支持主任',
    en: 'Director of Academic and Student Support',
  },
  {
    img: 'bert-barry',
    name: 'Bert Barry',
    school: '圣路易斯大学',
    title: '国际学生咨询顾问',
    en: 'International Student Advisor',
  },
  {
    img: 'richard-de-capua',
    name: 'Richard DeCapua',
    school: '波士顿学院',
    title: '前学生副院长',
    en: 'Former Associate Dean of Students',
  },
  {
    img: 'justin-jeffery',
    name: 'Justin Jeffery',
    school: '乔治亚大学',
    title: '国际学生生活主任',
    en: 'Director of International Student Life',
  },
  {
    img: 'cameron-smith',
    name: 'Cameron Smith',
    school: '波士顿大学',
    title: '学术咨询助理主任',
    en: 'Assistant Director of Academic Advising',
  },
  {
    img: 'shams-vahedi',
    name: 'Shams Vahedi, Esq',
    school: '密歇根州立大学',
    title: '国际学生与学者咨询顾问',
    en: 'International Student & Scholar Advisor',
  },
  {
    img: 'daymyen-lane',
    name: 'Daymyen Lane',
    school: '昆尼皮亚克大学',
    title: '多元文化事务主任',
    en: 'Director of Multicultural Affairs',
  },
  {
    img: 'john-singleton',
    name: 'John Singleton',
    school: '德克萨斯基督教大学',
    title: '国际学生项目主任',
    en: 'Director of International Student Programs',
  },
  {
    img: 'cissy-petty',
    name: 'Cissy Petty',
    school: '乔治华盛顿大学',
    title: '副校长',
    en: 'Vice President',
  },
  {
    img: 'frank-ross',
    name: 'Frank Ross',
    school: '巴特勒大学',
    title: '副校长',
    en: 'Vice President',
  },
  {
    img: 'jarod-hightower',
    name: 'Jarod Hightower - Mills',
    school: '德克萨斯大学奥斯汀分校',
    title: '国际学生顾问',
    en: 'International Student Advisor',
  },
  {
    img: 'johnathan-perkins',
    name: 'Johnathan Perkins',
    school: '加州大学洛杉矶分校',
    title: '副校长特别助理',
    en: 'Special Assistant to the Vice President',
  },
  {
    img: 'whitney-szmodis',
    name: 'Whitney Szmodis',
    school: '理海大学',
    title: '奖学金咨询助理主任',
    en: 'Assistant Director of Fellowship Advising',
  },
  {
    img: 'jason-escalera',
    name: 'Jason Escalera',
    school: '康涅狄格大学',
    title: '国际学生和学者专家',
    en: 'International Student & Scholar Specialist',
  },
  {
    img: 'jenny',
    name: 'Jenny Bowen',
    school: '印第安纳大学',
    title: '国际学生咨询主任',
    en: 'Director of International Student Advising',
  },
  {
    img: 'huan-roholt',
    name: 'Huan Roholt',
    school: '宾夕法尼亚大学',
    title: '国际学生与学者咨询顾问',
    en: 'Advisor to International Students & Scholars',
  },
]

const NA_CARD = [
  {
    img: 'academic-q&a',
    icon: 'home',
    title: '当地教育系统介绍',
    en: 'Introduction to the Local Education System',
  },
  {
    img: 'course-guide',
    icon: 'pencil',
    title: '行前准备指导',
    en: 'Guidance on Trips',
  },
  {
    img: 'academic-advance-plan',
    icon: 'question',
    title: '选课逻辑梳理',
    en: 'Course Selection Logics Analysis',
  },
  {
    img: 'high-quality-information',
    icon: 'fan',
    title: '学习能力和学习习惯评估',
    en: 'Assessment on Study Ability and Habit',
  },
  {
    img: 'monthly-learning-tracking',
    icon: 'focus',
    title: '月度学习追踪',
    en: 'Monthly Learning Tracking',
  },
  {
    img: 'summary-term-grades',
    icon: 'list',
    title: '学期成绩总结',
    en: 'Summary of Term Grades',
  },
]
const HOME_RMP = [
  {
    name: '郭',
    quote:
      '刚开始进入选课系统的时候，一头雾水。在其他同学都能正常选课的时候，我却无法进行选课。还好Matthew老师及时帮我检查了学校的账户，去掉了账户内的holds，在还有3个名额的时候抢到了我心仪教授的课！感恩Matthew！比心！',
    img: 'rmp1',
    bd_img: 'mathew',
    bd_name: 'Mathew',
    bd_title: '课程规划王牌老师',
    list: '<li>帮助近百位美国和加拿大学生完成大学选课规划</li><li>擅长为有转学需求的学生进行选课规划，转学分成功率高达95%。</li><li>荃程无忧的王牌课程规划老师。</li>',
  }
]

// 合作机构
const HOME_PARTNERS = [
  {
    img: 'isc',
    url: 'http://www.lanxum.com/',
    alt: '立思辰',
    height: '71',
    width: '151',
  },
  {
    img: 'td',
    url: 'http://tiandaoedu.com/',
    alt: '天道',
    height: '71',
    width: '151',
  },
  {
    img: 'sd',
    url: 'http://www.gsp.sdu.edu.cn/default.do',
    alt: '山东大学',
    height: '71',
    width: '151',
  },
  {
    img: 'hy-new',
    url: 'http://www.cdhuaying.com/',
    alt: '华樱',
    height: '77',
    width: '93',
  },
  {
    img: 'aoji',
    url: 'http://www.aoji.cn/',
    alt: '澳际',
    height: '71',
    width: '151',
  },
  {
    img: 'gedu',
    url: 'https://www.gedu.ca/',
    alt: '环球教育',
    height: '71',
    width: '151',
  },
  {
    img: 'zmn',
    url: 'http://www.zmnedu.com/',
    alt: '朴新啄木鸟',
    height: '40',
    width: '176',
  },
  {
    img: 'meishi',
    url: 'http://www.meishiedu.com/',
    alt: '美世教育',
    height: '25',
    width: '148',
  },
  {
    img: 'jq',
    url: 'https://www.oxbridgedu.org/',
    alt: '津桥国际',
    height: '53',
    width: '123',
  },
  {
    img: 'xhd',
    url: 'https://www.liuxue.xhd.cn/',
    alt: '新航道',
    height: '38',
    width: '200',
  },
]

// 家长的十大担忧
const ISS_QA = [
  {
    id: '01',
    text: '大学疫情相关<b>政策信息</b>',
    long: false,
  },
  {
    id: '02',
    text: '疫情防控下，大学对师生提供了哪些<b>帮助和措施</b>以应对危机？',
    long: false,
  },
  {
    id: '03',
    text: '对于即将到来的<b>2020秋季学期</b>，大学做出了哪些安排？',
    long: false,
  },
  {
    id: '04',
    text: '在<b>行前准备</b>阶段，留学生应该注意什么？',
    long: false,
  },
  {
    id: '05',
    text: '对于国际留学生的<b>签证申请</b>和<b>体检证明</b>，大学政策是否有相关政策改变或提供更多灵活性？',
    long: true,
  },
  {
    id: '06',
    text: '国际留学生应该如何<b>准备选课</b>？',
    long: false,
  },
  {
    id: '07',
    text: '大学是否在计划2020学年线上<b>orientation</b>？',
    long: false,
  },
  {
    id: '08',
    text: '疫情防控下，学生如何保持<b>学术成功</b>，追求学业进步？',
    long: false,
  },
  {
    id: '09',
    text: '疫情防控下，国际留学生应如何挖掘适合自己的<b>学习方法</b>？',
    long: false,
  },
  {
    id: '10',
    text: '针对上<b>网课</b>或<b>在家学习</b>，有什么建议可以提供给学生们？',
    long: true,
  },
]
// 名校
const ELITE_PROFILE = [
  {
    name: 'jonathan',
    info: '麻省理工学院学士<br>前匹兹堡大学招生面试官',
  },
  {
    name: 'zachary',
    info: '西北大学讲师<br>传记作家',
  },
  {
    name: 'melanie',
    info: '斯坦福大学讲师<br>硅谷创业家',
  },
  // {
  //   name: 'tina',
  //   info: '哈佛大学博士后<br>医学院讲师',
  // },
  {
    name: 'phillip',
    info: '耶鲁大学博士<br>商界精英',
  },
  {
    name: 'nathan',
    info: '多伦多大学<br>讲师、博士',
  },
  {
    name: 'dean',
    info: '多伦多大学博士<br>前巴黎大学商学院教授',
  },
]

const SERVICES_SERVICE = [
  {
    title: '选课指导',
    text: '该选什么课？哪门课容易拿高分？哪门课的挂科风险相对较小？哪位教授的授课风格适合自己？这些是每个远渡重洋的留学生在最初面对选课时都会思考的问题。<br><br>荃程无忧的海外选课导师根据学生的语言成绩、科目强项、未来发展方向、个人兴趣等个人情况，通过盖洛普测试挖掘学生自身优势，基于学校的毕业要求为学生量身定制课程并推荐教授，指导学生选择最适合的课程。',
    url: '/course-select',
    src: '/images/pages/services/course-sample.png',
  },
  //   {
  //     title: '学习资料',
  //     text: '国外大学教授授课并非依照教科书进行，自由度很高，加之口音、语速等因素，留学生在课堂上记笔记俨然成为了一项巨大的挑战。此外，国外大学对学生学术要求严格，
  //     海量的阅读和写作任务更是家常便饭，而海外教辅资料稀缺，留学生很难获得及时有效的学术帮助。<br><br>荃程无忧的学习资料包含精品资料和考前总结两部分内容。精品资料，
  //     即荃程无忧提取专业课程主流教科书的精华，涵盖专业课程的基础知识点，既可以用作课前预习，又可以在学习过程中起到辅助作用。<br><br>考前总结，则是根据学生所选的课程，
  //     匹配同班的当地学霸，在考前提供详细、高品质的考前总结，进而帮助学生提高复习效率。',
  //     url: '/study-resources',
  //     src: '/images/pages/services/notes-sample.png',
  //    reverse: true
  // },
  {
    title: '学术问答',
    text: '海外大学授课方式与国内大学迥然不同，尤其公立大学和大学一年级的课堂规模庞大大，且教授不坐班，学生在课堂上或在做作业时遇到学术问题，很难得到及时的解答。而在海外大学，作业的平均比重约占课程总分的10%-40%，因而高质量的完成每项作业，是学生获得高GPA的关键。<br><br>荃程无忧的精英导师团队帮助留学生在线解答学术问题。留学生可随时向荃程无忧的专业导师提问，以获得详尽的解答。荃程无忧的学术问答服务能够帮助学生快速熟悉解题思路，掌握解题技巧，提高作业正确率。',
    url: '/homework-help-na',
    src: '/images/pages/services/qa-sample.png',
  },
  {
    title: '作文润色',
    text: '在海外大学，通常论文分数占一门课总分的20-30%，并且，写出合格的论文是很多学校毕业的必要条件。而相较于以英语为母语的当地学生而言，留学生在论文写作方面缺乏足够的竞争力。<br><br>写完文章只是完成了一半，修改同等重要。作文润色，即帮助学生润色包括小论文、演讲稿、实验报告、项目报告在内的各种学术写作任务，以提高写作质量。荃程无忧的作文润色导师会对留学生的作文进行修改、润色，包括对语法、标点、时态、拼写等进行校对，对语言、结构和格式的调整，提高作文的质量，确保学生论文写作方面获得优势，提高写作能力。',
    url: '/essay-na',
    src: '/images/pages/services/essay-sample.png',
    reverse: true,
  },
  {
    title: '学术发展指导体系',
    text: '学生发展指导体系是荃程无忧专业发展导师团队提供的一系列以学生为核心的全方位能力提升发展指导。顺利毕业并取得学位只是留学成功的基本直接回报，而通过海外教育的过程，磨砺并掌握职业发展和幸福生活所需要的技能和习惯，则是个人成长和发展的最大收获。<br><br>荃程无忧的学生发展导师团队由心理学家领衔，多位教育学、心理学和职业发展规划师等专业人士组成，将以专业的理论基础和丰富的实践经验，根据学生的自身情况进行的评估，提供针对学习能力的综合指导和提高个人实力的进阶指导服务。',
    url: '/sdc-service',
    src: '/images/pages/services/sdc-sample.png',
  },
  {
    title: '学长领航',
    text: '如何选择专业？如何解决住宿？如何选择室友？如何顺利通过海关？哪家手机运营商的套餐最合算？房东要毁约怎么办？不适应现在的教授怎么办？所学专业的毕业要求到底是什么？学生拿到Offer后，激动与兴奋之余，开始担忧很多问题。<br><br>荃程无忧的学长领航服务为留学生提供在线的留学问答。为保证回答的客观性及准确性，荃程无忧为学生匹配同院校同专业的学长，通过荃程无忧的认证和培训，保证为留学生提供完善准确的生活信息和快捷的在线帮助。',
    url: '/wenda',
    src: '/images/pages/services/mentor-sample.png',
    reverse: true,
  },
  {
    title: '院校百科',
    text: '荃程无忧院校百科汇集海外各大院校一手资料，集衣、食、住、行为一体，图文并茂，生动展示所在城市的环境、气候、校园周边、在校资源、当地生活等重要信息，是一本可以随时查阅的新生手册。<br><br>海外各大院校在读的高年级学生作为“海外生活导师”为新生们收集来自当地的一手生活素材，撰写所在学校的荃程无忧院校百科，定期维护更新，以留学生视角带你领略海外风光，与你分享当地风土人情，向你展示丰富多彩的留学生活，享受属于自己的留学时光。',
    url: '/guides',
    src: '/images/pages/services/guides.jpg',
  },
]

const GUIDES_CATEGORY = [
  {
    title: '行前<br>准备',
    src: '/images/pages/guides/guide-1.jpg',
    text: '即将在新的城市开始新生活，你了解这里吗？院校百科以城市简介为开篇，详细介绍所在城市的环境、气候、景点、机场设施，帮助即将开启异国行的你做好充足的行前准备，学长学姐们将多次往返的经验传授于你。',
  },
  {
    title: '校园<br>相关',
    src: '/images/pages/guides/guide-2.jpg',
    text: '迎新、上课、复习……你将在校园里度过大部分时光，各项挑战也都在这里等着你。除了需要了解校园里的各项设施，你也要知道如何解决交学费、选课、宿舍申请等现实问题，在院校百科里查找这些信息吧。',
  },
  {
    title: '当地<br>生活',
    src: '/images/pages/guides/guide-3.jpg',
    text: '办电话卡、银行卡、付房租，你知道如何处理这些琐事吗？去哪里买东西？哪里有地道的美食？这些学长学姐们遇到过的问题，你也会碰到，请看他们在院校百科里精心准备的城市生活攻略。',
  },
  {
    title: '附<br>录',
    src: '/images/pages/guides/guide-4.jpg',
    text: '一个人的生活总会出现各种各样的状况，除了上述信息，你可能还会需要这些关于你所在学校的更多信息。',
  },
]

const INDEX_SEO_CONFIG = {
  title: '荃程无忧 quancheng | 让海外学习变简单',
  description: '2010年创立于加拿大多伦多的荃程无忧，致力于为留学生提供系统的解决方案',
  keywords: '荃程无忧, quancheng, 留学论文,美国留学,加拿大留学,澳大利亚留学, 英国留学',
}

const TUTOR_LOGIN_SEO_CONFIG = {
  title: 'quancheng | Shenzhen Quancheng Information Technology Co., LTD',
  description: 'Shenzhen Quancheng Information Technology Co., LTD. (hereinafter referred to as "Quanchengwuyou") was founded in Toronto, Canada in 2010, has an academic team composed of more than 1,000 famous teachers and hundreds of student development tutors. It provides independent copyright system and personalized curriculum guidance for overseas students studying or going overseas to study in secondary school, pre-undergraduate, undergraduate and postgraduate language level. The products include student development coach team services, course selection guidance, LT, QA, and EE to help clients cope with the challenges of overseas education, improve GPA and maintain excellent academic performance, gain competitive advantage in international education.',
  keywords: '荃程无忧, quancheng, Quanchengwuyou',
}

const HEADER_LINK = [
  {
    label: '服务国家',
    children: [
      {
        label: '美国',
        href: '/country-us',
        description: '美国留学',
        keywords: '美国留学, 留学论文提升',
      },
      {
        label: '加拿大',
        href: '/country-ca',
      },
      {
        label: '澳大利亚',
        href: '/country-aus',
      },
      {
        label: '英国',
        href: '/country-uk',
      },
      {
        label: '新西兰',
        href: '/country-nzl',
      },
    ],
  },
  {
    label: '大学学术培优',
    children: [
      {
        label: '视频辅导',
        href: '/live-tutorial',
      },
      {
        label: '论文指导',
        href: '/essay-guidance',
      },
      {
        label: '选课指导',
        href: '/course-select',
        location: 'course_select_pages',
      },
      {
        label: '学术先修',
        href: '/prep-course',
      },

      {
        label: '定校解析',
        href: '/offer-interpretation',
      },
      {
        label: '专业解读',
        href: '/major-consulting',
      },
      {
        label: '暑期规划',
        href: '/onboard-planning',
      },
      {
        label: '学生发展指导',
        href: '/sdc-introduction',
      },
      // { label: '线上大学', href: '' },
    ],
  },
  {
    label: '中学学术培优',
    children: [
      {
        label: 'IB & IBEE',
        href: '/ib-ibee',
      },
      {
        label: 'AP',
        href: '/ap',
      },
      {
        label: '美加高中',
        href: '/na-highschool',
      },
    ],
  },
  {
    label: '导师阵容',
    children: [
      {
        label: '导师生态系统',
        href: '/educators-system',
      },
      {
        label: '美加导师',
        href: '/na-educators',
      },
      {
        label: '英澳导师',
        href: '/ea-educators',
      },
    ],
  },
  {
    label: '学生发展指导',
    children: [
      {
        label: '指导体系',
        href: '/sdc',
        title: '学生发展指导体系',
        // location: 'sdc_pages',
      },
      {
        label: '专业团队',
        href: '/sdc-introduction',
      },
      {
        label: '强化培优',
        href: '/sdc-system',
      },
    ],
  },
  {
    label: '荃程4.0俱乐部',
    children: [
      {
        label: '为何选择荃程',
        href: '/why',
        location: 'why_pages',
      },
      {
        label: '捷报分享',
        href: '/transcripts',
      },
    ],
  },
  {
    label: '关于我们',
    href: '/about',
  },
]

const LOGOUT_LINK = [
  ...HEADER_LINK,
  {
    label: '常见问题',
    href: '/faq',
  },
  {
    label: '院校百科',
    href: '/guides',
  },
  {
    label: '加入我们',
    href: '/careers',
  },
  {
    label: '联系我们',
    href: '/contact',
  },
  {
    label: '荃程无忧团队',
    href: '/team',
  },
  {
    label: '隐私条款',
    href: '/privacy',
  },
  {
    label: '服务院校',
    href: '/schools',
  },
  {
    label: '专属留学辅助服务',
    href: '/services',
  },
  {
    label: '服务条款',
    href: '/terms',
  },
]

const PROVINCE_CHINA = [
  ['北京市', '北京市'],
  ['上海市', '上海市'],
  ['天津市', '天津市'],
  ['重庆市', '重庆市'],
  ['黑龙江省', '黑龙江省'],
  ['吉林省', '吉林省'],
  ['辽宁省', '辽宁省'],
  ['内蒙古', '内蒙古'],
  ['河北省', '河北省'],
  ['新疆', '新疆'],
  ['甘肃省', '甘肃省'],
  ['青海省', '青海省'],
  ['陕西省', '陕西省'],
  ['宁夏', '宁夏'],
  ['河南省', '河南省'],
  ['山东省', '山东省'],
  ['山西省', '山西省'],
  ['安徽省', '安徽省'],
  ['湖北省', '湖北省'],
  ['湖南省', '湖南省'],
  ['江苏省', '江苏省'],
  ['四川省', '四川省'],
  ['贵州省', '贵州省'],
  ['云南省', '云南省'],
  ['广西省', '广西省'],
  ['西藏', '西藏'],
  ['浙江省', '浙江省'],
  ['江西省', '江西省'],
  ['广东省', '广东省'],
  ['福建省', '福建省'],
  ['台湾省', '台湾省'],
  ['海南省', '海南省'],
  ['香港', '香港'],
  ['澳门', '澳门'],
]

const CL_PAGES = [
  {
    href: '/user/essay-demo',
    cl: 'pages_essay_demo',
  },
  {
    href: '/user/email-demo',
    cl: 'pages_email_demo',
  },
  {
    href: '/user/qa-demo',
    cl: 'pages_qa_demo',
  },
  {
    href: '/user/process',
    location: 'wait_process_users',
  },
  {
    href: '/user/gallup',
    location: 'upload_gallup_users',
  },
  {
    href: '/user/pay-process',
    location: 'pay_process_users',
  },
  {
    href: '/user/result',
    location: 'result_gallup_test',
  },
  {
    location: '学长领航',
    href: '/user/life-questions',
  },
  {
    location: '学术问答',
    href: '/user/experts',
  },
  {
    location: '沟通辅助',
    href: '/user/email-helper',
  },
  {
    location: '作文润色',
    href: '/user/essayhelp',
  },
  {
    location: '护航先修',
    href: '/user/learning_center',
  },
  {
    location: '设置',
    href: '/user/setting',
  },
  {
    location: '',
    href: '/user/pay-process',
  },
  {
    location: 'welcome',
    href: '/user/welcome',
  },
  {
    location: '',
    href: '/user/step1',
  },
  {
    location: '',
    href: '/user/step2',
  },
  {
    location: '',
    href: '/user/step3',
  },
  {
    location: '',
    href: '/user/process',
  },
  {
    location: '',
    href: '/user/payment',
  },
  {
    location: '',
    href: '/user/qa-demo',
  },
  {
    location: '',
    href: '/user/essay-demo',
  },
  {
    location: '',
    href: '/user/email-demo',
  },
  {
    location: '',
    href: '/user/qa-econ-demo',
  },
  {
    location: '',
    href: '/user/qa-email-demo',
  },
  {
    location: '',
    href: '/user/qa-math-demo',
  },
  {
    location: '',
    href: '/user/qa-stats-demo',
  },
  {
    location: '',
    href: '/user/qa-stats-demo',
  },
  {
    location: '/选课指导',
    href: '/user/courses_landing',
  },
  {
    location: '视频辅导',
    href: '/user/live-tutorial-landing',
  },
  {
    location: '选课指导',
    href: '/user/courses_pending',
  },
  {
    location: 'course_select_courses',
    href: '/user/courses',
  },
  {
    location: 'notes_landing_pages',
    href: '/user/notes_landing',
  },
]
const TUTOR_LOGOUT_PAGE = [
  '/tutor/login',
  '/tutor',
  '/tutor/signup',
  '/tutor/reset_password',
]
const BLANK_LAYOUT_PAGE = [
  '/become-a-tutor',
  '/become-a-gandue',
  '/become-aunz-gandue',
  '/become-a-tutor/apply',
  '/become-a-tutor/terms',
  '/become-a-tutor/privacy-policy',
  '/chasegenius',
  '/democlass'
]
const TIME_ZONE = [
  ['International Date Line West', '(GMT-12:00) International Date Line West'],
  ['American Samoa', '(GMT-11:00) American Samoa'],
  ['Midway Island', '(GMT-11:00) Midway Island'],
  ['Hawaii', '(GMT-10:00) Hawaii'],
  ['Alaska', '(GMT-09:00) Alaska'],
  ['Pacific Time (US & Canada)', '(GMT-08:00) Pacific Time (US & Canada)'],
  ['Tijuana', '(GMT-08:00) Tijuana'],
  ['Arizona', '(GMT-07:00) Arizona'],
  ['Chihuahua', '(GMT-07:00) Chihuahua'],
  ['Mazatlan', '(GMT-07:00) Mazatlan'],
  ['Mountain Time (US & Canada)', '(GMT-07:00) Mountain Time (US & Canada)'],
  ['Central America', '(GMT-06:00) Central America'],
  ['Central Time (US & Canada)', '(GMT-06:00) Central Time (US & Canada)'],
  ['Guadalajara', '(GMT-06:00) Guadalajara'],
  ['Mexico City', '(GMT-06:00) Mexico City'],
  ['Monterrey', '(GMT-06:00) Monterrey'],
  ['Saskatchewan', '(GMT-06:00) Saskatchewan'],
  ['Bogota', '(GMT-05:00) Bogota'],
  ['Eastern Time (US & Canada)', '(GMT-05:00) Eastern Time (US & Canada)'],
  ['Indiana (East)', '(GMT-05:00) Indiana (East)'],
  ['Lima', '(GMT-05:00) Lima'],
  ['Quito', '(GMT-05:00) Quito'],
  ['Atlantic Time (Canada)', '(GMT-04:00) Atlantic Time (Canada)'],
  ['Caracas', '(GMT-04:00) Caracas'],
  ['Georgetown', '(GMT-04:00) Georgetown'],
  ['La Paz', '(GMT-04:00) La Paz'],
  ['Puerto Rico', '(GMT-04:00) Puerto Rico'],
  ['Santiago', '(GMT-04:00) Santiago'],
  ['Newfoundland', '(GMT-03:30) Newfoundland'],
  ['Brasilia', '(GMT-03:00) Brasilia'],
  ['Buenos Aires', '(GMT-03:00) Buenos Aires'],
  ['Greenland', '(GMT-03:00) Greenland'],
  ['Montevideo', '(GMT-03:00) Montevideo'],
  ['Mid-Atlantic', '(GMT-02:00) Mid-Atlantic'],
  ['Azores', '(GMT-01:00) Azores'],
  ['Cape Verde Is.', '(GMT-01:00) Cape Verde Is.'],
  ['Casablanca', '(GMT+00:00) Casablanca'],
  ['Dublin', '(GMT+00:00) Dublin'],
  ['Edinburgh', '(GMT+00:00) Edinburgh'],
  ['Lisbon', '(GMT+00:00) Lisbon'],
  ['London', '(GMT+00:00) London'],
  ['Monrovia', '(GMT+00:00) Monrovia'],
  ['UTC', '(GMT+00:00) UTC'],
  ['Amsterdam', '(GMT+01:00) Amsterdam'],
  ['Belgrade', '(GMT+01:00) Belgrade'],
  ['Berlin', '(GMT+01:00) Berlin'],
  ['Bern', '(GMT+01:00) Bern'],
  ['Bratislava', '(GMT+01:00) Bratislava'],
  ['Brussels', '(GMT+01:00) Brussels'],
  ['Budapest', '(GMT+01:00) Budapest'],
  ['Copenhagen', '(GMT+01:00) Copenhagen'],
  ['Ljubljana', '(GMT+01:00) Ljubljana'],
  ['Madrid', '(GMT+01:00) Madrid'],
  ['Paris', '(GMT+01:00) Paris'],
  ['Prague', '(GMT+01:00) Prague'],
  ['Rome', '(GMT+01:00) Rome'],
  ['Sarajevo', '(GMT+01:00) Sarajevo'],
  ['Skopje', '(GMT+01:00) Skopje'],
  ['Stockholm', '(GMT+01:00) Stockholm'],
  ['Vienna', '(GMT+01:00) Vienna'],
  ['Warsaw', '(GMT+01:00) Warsaw'],
  ['West Central Africa', '(GMT+01:00) West Central Africa'],
  ['Zagreb', '(GMT+01:00) Zagreb'],
  ['Zurich', '(GMT+01:00) Zurich'],
  ['Athens', '(GMT+02:00) Athens'],
  ['Bucharest', '(GMT+02:00) Bucharest'],
  ['Cairo', '(GMT+02:00) Cairo'],
  ['Harare', '(GMT+02:00) Harare'],
  ['Helsinki', '(GMT+02:00) Helsinki'],
  ['Jerusalem', '(GMT+02:00) Jerusalem'],
  ['Kaliningrad', '(GMT+02:00) Kaliningrad'],
  ['Kyiv', '(GMT+02:00) Kyiv'],
  ['Pretoria', '(GMT+02:00) Pretoria'],
  ['Riga', '(GMT+02:00) Riga'],
  ['Sofia', '(GMT+02:00) Sofia'],
  ['Tallinn', '(GMT+02:00) Tallinn'],
  ['Vilnius', '(GMT+02:00) Vilnius'],
  ['Baghdad', '(GMT+03:00) Baghdad'],
  ['Istanbul', '(GMT+03:00) Istanbul'],
  ['Kuwait', '(GMT+03:00) Kuwait'],
  ['Minsk', '(GMT+03:00) Minsk'],
  ['Moscow', '(GMT+03:00) Moscow'],
  ['Nairobi', '(GMT+03:00) Nairobi'],
  ['Riyadh', '(GMT+03:00) Riyadh'],
  ['St. Petersburg', '(GMT+03:00) St. Petersburg'],
  ['Volgograd', '(GMT+03:00) Volgograd'],
  ['Tehran', '(GMT+03:30) Tehran'],
  ['Abu Dhabi', '(GMT+04:00) Abu Dhabi'],
  ['Baku', '(GMT+04:00) Baku'],
  ['Muscat', '(GMT+04:00) Muscat'],
  ['Samara', '(GMT+04:00) Samara'],
  ['Tbilisi', '(GMT+04:00) Tbilisi'],
  ['Yerevan', '(GMT+04:00) Yerevan'],
  ['Kabul', '(GMT+04:30) Kabul'],
  ['Ekaterinburg', '(GMT+05:00) Ekaterinburg'],
  ['Islamabad', '(GMT+05:00) Islamabad'],
  ['Karachi', '(GMT+05:00) Karachi'],
  ['Tashkent', '(GMT+05:00) Tashkent'],
  ['Chennai', '(GMT+05:30) Chennai'],
  ['Kolkata', '(GMT+05:30) Kolkata'],
  ['Mumbai', '(GMT+05:30) Mumbai'],
  ['New Delhi', '(GMT+05:30) New Delhi'],
  ['Sri Jayawardenepura', '(GMT+05:30) Sri Jayawardenepura'],
  ['Kathmandu', '(GMT+05:45) Kathmandu'],
  ['Almaty', '(GMT+06:00) Almaty'],
  ['Astana', '(GMT+06:00) Astana'],
  ['Dhaka', '(GMT+06:00) Dhaka'],
  ['Urumqi', '(GMT+06:00) Urumqi'],
  ['Rangoon', '(GMT+06:30) Rangoon'],
  ['Bangkok', '(GMT+07:00) Bangkok'],
  ['Hanoi', '(GMT+07:00) Hanoi'],
  ['Jakarta', '(GMT+07:00) Jakarta'],
  ['Krasnoyarsk', '(GMT+07:00) Krasnoyarsk'],
  ['Novosibirsk', '(GMT+07:00) Novosibirsk'],
  ['Beijing', '(GMT+08:00) Beijing'],
  ['Chongqing', '(GMT+08:00) Chongqing'],
  ['Hong Kong', '(GMT+08:00) Hong Kong'],
  ['Irkutsk', '(GMT+08:00) Irkutsk'],
  ['Kuala Lumpur', '(GMT+08:00) Kuala Lumpur'],
  ['Perth', '(GMT+08:00) Perth'],
  ['Singapore', '(GMT+08:00) Singapore'],
  ['Taipei', '(GMT+08:00) Taipei'],
  ['Ulaanbaatar', '(GMT+08:00) Ulaanbaatar'],
  ['Osaka', '(GMT+09:00) Osaka'],
  ['Sapporo', '(GMT+09:00) Sapporo'],
  ['Seoul', '(GMT+09:00) Seoul'],
  ['Tokyo', '(GMT+09:00) Tokyo'],
  ['Yakutsk', '(GMT+09:00) Yakutsk'],
  ['Adelaide', '(GMT+09:30) Adelaide'],
  ['Darwin', '(GMT+09:30) Darwin'],
  ['Brisbane', '(GMT+10:00) Brisbane'],
  ['Canberra', '(GMT+10:00) Canberra'],
  ['Guam', '(GMT+10:00) Guam'],
  ['Hobart', '(GMT+10:00) Hobart'],
  ['Melbourne', '(GMT+10:00) Melbourne'],
  ['Port Moresby', '(GMT+10:00) Port Moresby'],
  ['Sydney', '(GMT+10:00) Sydney'],
  ['Vladivostok', '(GMT+10:00) Vladivostok'],
  ['Magadan', '(GMT+11:00) Magadan'],
  ['New Caledonia', '(GMT+11:00) New Caledonia'],
  ['Solomon Is.', '(GMT+11:00) Solomon Is.'],
  ['Srednekolymsk', '(GMT+11:00) Srednekolymsk'],
  ['Auckland', '(GMT+12:00) Auckland'],
  ['Fiji', '(GMT+12:00) Fiji'],
  ['Kamchatka', '(GMT+12:00) Kamchatka'],
  ['Marshall Is.', '(GMT+12:00) Marshall Is.'],
  ['Wellington', '(GMT+12:00) Wellington'],
  ['Chatham Is.', '(GMT+12:45) Chatham Is.'],
  ["Nuku'alofa", "(GMT+13:00) Nuku'alofa"],
  ['Samoa', '(GMT+13:00) Samoa'],
  ['Tokelau Is.', '(GMT+13:00) Tokelau Is.'],
]

const TIME_ZONE_OFFSET = {
  'International Date Line West': -12,
  'American Samoa': -11,
  'Midway Island': -11,
  Hawaii: -10,
  Alaska: -9,
  'Pacific Time (US & Canada)': -8,
  Tijuana: -8,
  Arizona: -7,
  Chihuahua: -7,
  Mazatlan: -7,
  'Mountain Time (US & Canada)': -7,
  'Central America': -6,
  'Central Time (US & Canada)': -6,
  Guadalajara: -6,
  'Mexico City': -6,
  Monterrey: -6,
  Saskatchewan: -6,
  Bogota: -5,
  'Eastern Time (US & Canada)': -5,
  'Indiana (East)': -5,
  Lima: -5,
  Quito: -5,
  'Atlantic Time (Canada)': -4,
  Caracas: -4,
  Georgetown: -4,
  'La Paz': -4,
  'Puerto Rico': -4,
  Santiago: -4,
  Newfoundland: -4,
  Brasilia: -3,
  'Buenos Aires': -3,
  Greenland: -3,
  Montevideo: -3,
  'Mid-Atlantic': -2,
  Azores: -1,
  'Cape Verde Is.': -1,
  Casablanca: 0,
  Dublin: 0,
  Edinburgh: 0,
  Lisbon: 0,
  London: 0,
  Monrovia: 0,
  UTC: 0,
  Amsterdam: 1,
  Belgrade: 1,
  Berlin: 1,
  Bern: 1,
  Bratislava: 1,
  Brussels: 1,
  Budapest: 1,
  Copenhagen: 1,
  Ljubljana: 1,
  Madrid: 1,
  Paris: 1,
  Prague: 1,
  Rome: 1,
  Sarajevo: 1,
  Skopje: 1,
  Stockholm: 1,
  Vienna: 1,
  Warsaw: 1,
  'West Central Africa': 1,
  Zagreb: 1,
  Zurich: 1,
  Athens: 2,
  Bucharest: 2,
  Cairo: 2,
  Harare: 2,
  Helsinki: 2,
  Jerusalem: 2,
  Kaliningrad: 2,
  Kyiv: 2,
  Pretoria: 2,
  Riga: 2,
  Sofia: 2,
  Tallinn: 2,
  Vilnius: 2,
  Baghdad: 3,
  Istanbul: 3,
  Kuwait: 3,
  Minsk: 3,
  Moscow: 3,
  Nairobi: 3,
  Riyadh: 3,
  'St. Petersburg': 3,
  Volgograd: 3,
  Tehran: 3,
  'Abu Dhabi': 4,
  Baku: 4,
  Muscat: 4,
  Samara: 4,
  Tbilisi: 4,
  Yerevan: 4,
  Kabul: 4,
  Ekaterinburg: 5,
  Islamabad: 5,
  Karachi: 5,
  Tashkent: 5,
  Chennai: 5,
  Kolkata: 5,
  Mumbai: 5,
  'New Delhi': 5,
  'Sri Jayawardenepura': 5,
  Kathmandu: 5,
  Almaty: 6,
  Astana: 6,
  Dhaka: 6,
  Urumqi: 6,
  Rangoon: 6,
  Bangkok: 7,
  Hanoi: 7,
  Jakarta: 7,
  Krasnoyarsk: 7,
  Novosibirsk: 7,
  Beijing: 8,
  Chongqing: 8,
  'Hong Kong': 8,
  Irkutsk: 8,
  'Kuala Lumpur': 8,
  Perth: 8,
  Singapore: 8,
  Taipei: 8,
  Ulaanbaatar: 8,
  Osaka: 9,
  Sapporo: 9,
  Seoul: 9,
  Tokyo: 9,
  Yakutsk: 9,
  Adelaide: 9,
  Darwin: 9,
  Brisbane: 10,
  Canberra: 10,
  Guam: 10,
  Hobart: 10,
  Melbourne: 10,
  'Port Moresby': 10,
  Sydney: 10,
  Vladivostok: 10,
  Magadan: 11,
  'New Caledonia': 11,
  'Solomon Is.': 11,
  Srednekolymsk: 11,
  Auckland: 12,
  Fiji: 12,
  Kamchatka: 12,
  'Marshall Is.': 12,
  Wellington: 12,
  'Chatham Is.': 12,
  "Nuku'alofa": 13,
  Samoa: 13,
  'Tokelau Is.': 13,
}

const DEFAULT_MESSAGE = [
  ['Greeting', 'Thanks for your question, I’d be happy to help!'],
  [
    'Ask for clarification',
    'I’m sorry, could you provide additional details or attach the outline so I can better answer your question.',
  ],
  [
    "Solution's ready",
    'I have just gone ahead and attached the solution. I hope this is clear enough. If you have any questions, feel free to leave a comment.',
  ],
  [
    'Ask for a review',
    'If you don’t have any other questions about my answer, could you provide a rating for me? Thanks so much!',
  ],
]

const university = [
  {
    values: 'us_university',
    label: 'Tutoring or Teaching in US university',
  },
  {
    values: 'ca_university',
    label: 'Tutoring or Teaching in Canada university',
  },
  {
    values: 'uk_university',
    label: 'Tutoring or Teaching in UK university',
  },
  {
    values: 'au_university',
    label: 'Tutoring or Teaching in Australia university',
  },
  {
    values: 'ib_high_school',
    label: 'IB Curriculum',
  },
  {
    values: 'ap_high_school',
    label: 'AP Exam',
  },
  {
    values: 'a_level_high_school',
    label: 'A-Level Exam',
  },
]

export {
  USER_PHONE_CODE_SELECT,
  TUTOR_PHONE_CODE_OPTIONS,
  USER_COUNTRY,
  QUESTION_TYPES,
  COURSE_STAGES,
  COURSE_SUGGESTION,
  COURSE_FLOW,
  HOMEWORK_TUTORS,
  LT__CHALLENGES,
  LT__INFO,
  LT__TYPES,
  LT__STEPS,
  LT__TUTORS,
  WHY_REASONS,
  ABOUT_EDUCATORS,
  ABOUT_PARTNERS,
  ABOUT_INVERTORS,
  TEAM_MEMBERS_CN,
  TEAM_VP,
  TEAM_MEMBERS_NA,
  TEAM_FOUNDERS,
  CONTACTS,
  ISS_CARD,
  HOME_RMP,
  HOME_PARTNERS,
  ISS_QA,
  ELITE_PROFILE,
  SERVICES_SERVICE,
  GUIDES_CATEGORY,
  INDEX_SEO_CONFIG,
  TUTOR_LOGIN_SEO_CONFIG,
  HEADER_LINK,
  LOGOUT_LINK,
  PROVINCE_CHINA,
  CL_PAGES,
  NA_CARD,
  TIME_ZONE,
  TUTOR_LOGOUT_PAGE,
  DEFAULT_MESSAGE,
  TIME_ZONE_OFFSET,
  BLANK_LAYOUT_PAGE,
  university,
}

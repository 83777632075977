/* eslint-disable react/jsx-props-no-spreading */

/**
 *@Description image
 *@author meng wang
 *@date 2020/7/24
 */
import Image from 'next/image'

const sanityIoImageLoader = ({src, width, quality}) => `${process.env.NEXT_PUBLIC_ASSETS_CDN || ''}${src}?w=${width}&q=${quality || 75}`

export default function NextImage({ ...rest }) {
  return (
    <Image
      {...rest}
      loader={sanityIoImageLoader}
    />
  )
}

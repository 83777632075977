/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

/**
 *@Description 输入框按钮组合
 *@author meng wang
 *@date 2020/7/24
 */
import React from 'react'
import Btn from './btn'

export default function TextBtnGroup({btn_class, btn, icon, inputProps, btn_disabled, onClick}) {
  let cl = 'c-form-field c-form-field--lg c-form-field--primary u-border-r-r-0 code_field js-users__validation-code'
  if (icon) cl += ' c-form-control--with-icon'

  return (
    <div className="c-form-control">
      <div className="l-row no-gutters">
        <div className="l-col">
          <input {...inputProps} className={cl} />
        </div>
        <div className="l-col-auto u-d-flex">
          <Btn
            disabled={btn_disabled}
            cl={`c-form-control__btn u-border-l-r-0 js-text-btn-group__btn ${btn_class}`}
            tag="button"
            content={btn || ''}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  )
}

/* eslint-disable camelcase  */
/**
 *@Description tabs
 *@author meng wang
 *@date 2020/7/23
 */

export default function Tabs({items, tabExchange, active = 0}) {
  return (
    <ul className="c-nav-tabs c-tabs-new">
      {
        items.map((item, index) => {
          const base_classname = `${active === index ? 'c-tabs-new__item__active' : ''}  ${item.is_disabled ? 'is-disabled' : ''}`
          return (
            <li
              className={`c-nav-tabs__item c-tabs-new__item ${base_classname}`}
              key={item.label}
              onClick={() => tabExchange(item, index)}
            >
              <a
                href={item.href}
                data-toggle={item.toggle}
                aria-controls={items.controls}
                role="tab"
              >
                {item.label}
              </a>
            </li>
          )
        })
      }
    </ul>
  )
}

/* eslint-disable */
import _ from 'lodash'
import Router from 'next/router'
import Hashids from 'hashids'
import download from 'downloadjs'
const ALLOW_TOP_MENU = ['demo', 'active']

const hashids = new Hashids('easyke_tutor_applicants', 20)
export default {
  c_mods(mods, base = false, sep = ' ', mod_sep = '--') {
    let mods_arr = (mods instanceof String) ? mods.split(sep) : mods
    mods_arr = _.reject(mods_arr, (m) => _.isEmpty(m))

    let base_name = mods_arr.shift()
    mods_arr = _.flatten(mods_arr)
    let mod_prefix = base_name + mod_sep

    let mods_str = mods_arr.map(m => sep + mod_prefix + m).join('')

    if (base) {
      mods_str += sep + base_name
    }

    return mods_str.trim()
  },

  pc_or_mobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  },

  formatDate(date) {
    return date ? date.substring(0, 19).replace('T', ' ') : ''
  },

  getRedirectUrl(status) {
    switch (status) {
      case 'request_school':
        return '/request-school'
      case 'step1':
        return '/user/step1'
      case 'step2':
        return '/user/step2'
      // case 'suspend':
      //   return '/logout'
      case 'step3':
        return '/user/step3'
      case 'demo':
        return '/user/dashboard'
      case 'choose_payment':
        return '/choose-payment'
      case 'disabled':
        return '/'
      case 'active':
        return '/user/dashboard'
      case 'welcome':
        return '/user/welcome'
      case 'gallup_test':
        return '/gallup'
      case 'course':
        return '/course-helper'
      case 'wait1':
        return '/user/process'
      case 'wait2':
        return '/user/process'
      case 'wait3':
        return '/user/process'
      case 'wait4':
        return '/user/process'
      case 'wait5':
        return '/user/process'
      case 'result':
        return '/result'
      case 'pay_wait':
        return '/user/pay-process'
      case 'deferred':
        return '/user/deferred'
      default:
        return '/user/dashboard'
    }
  },
  redirectTutorByUrl(tutor, ctx) {
    if (!tutor.is_qa_enabled && ctx?.pathname === '/tutor/qa') {
      if (!tutor.is_lt_enabled) {
        return '/tutor/login'
      } else {
        return '/tutor/live_tutorials'
      }
    } else if (!tutor.is_lt_enabled && ctx?.pathname === '/tutor/live-tutorials') {
      if (!tutor.is_qa_enabled) {
        return '/tutor/login'
      } else {
        return '/tutor/qa'
      }
    }
    return null
  },
  redirectByUrl(user, ctx) {
    const BOTTOM_URL = ['/about', '/team', '/careers', '/contact', '/terms', '/privacy']
    // 登录后的用户无法访问logout页面
    if (!(ctx?.pathname || Router.pathname).startsWith('/user') && user && !BOTTOM_URL.includes(ctx?.pathname || Router.pathname)) {
      return this.getRedirectUrl(user.status)
    }
    if (user.status !== 'demo' && user.status !== 'active' && user.status !== 'suspend') {
      if ((ctx?.pathname || Router.pathname) !== this.getRedirectUrl(user.status)) {
        return this.getRedirectUrl(user.status)
      }
    }
    switch (ctx?.pathname || Router.pathname) {
      case '/':
        if (user) {
          return this.getRedirectUrl(user.status)
        }
        break
      case '/user/qa-math-demo':
      case '/user/qa-stats-demo':
      case '/user/qa-econ-demo':
      case '/user/qa-email-demo':
        if (!user) {
          return '/'
        }
        break
      case '/user/qa-demo':
      case '/user/essay-demo':
        if (user && !ALLOW_TOP_MENU.includes(user.status)) {
          return '/user/dashboard'
        }
        break
      case '/user/step1':
        if (!(user && (user.status === 'step1' || user.status === 'demo'))) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/step2':
        if (!(user && user.status === 'step2')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/step3':
        if (!(user && user.status === 'step3')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/process':
        if (!(user && ['wait1', 'wait2', 'wait3', 'wait4', 'wait5'].includes(user.status))) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/pay-process':
        if (!(user && user.status === 'pay_wait')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/payment':
        if (!(user && user.status === 'demo')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/welcome':
        if (!(user && user.status === 'welcome')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/dashboard':
        if (!(user && this.getRedirectUrl(user.status) === '/user/dashboard')) {
          if (user) {
            return this.getRedirectUrl(user.status)
          } else {
            return '/'
          }
        }
        break
      case '/user/courses_pending':
        if (user) {
          const service = user.services.filter((s) => s.name === '选课指导')
          if (service.length && service[0].url !== '/courses_pending') {
            return service[0].url
          }
        } else {
          return '/'
        }
        break
      case '/user/courses':
        if (user) {
          const service = user.services.filter((s) => s.name === '选课指导')
          if (service.length && service[0].url !== '/courses') {
            return service[0].url
          }
        } else {
          return '/'
        }
        break
    }
    return null
  },
  groupBy(array, f) {
    let groups = {}
    array.forEach(function (o) {
      let group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map(function (group) {
      return { [group]: groups[group] }
    })
  },
  downloadFile(e, url, name, loading, setLoading) {
    if (loading) {
      e.preventDefault()
      return
    }
    const types = ['.pdf', '.png', '.jpg', '.jpeg', '.gif', '.jfif', '.doc', '.docx', '.csv', '.xlsx', '.xls', '.ppt', '.pptx', '.rar', '.zip']
    if (!types.some((t) => name.endsWith(t))) {
      e.preventDefault()
      setLoading(true)
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = 'blob';
      x.onload = function (e) { 
        setLoading(false)
        download(x.response, name); 
      }
      x.send();
    }
  },
  encode(str) {
    const val = str + 111123441122342
    return hashids.encode(val)
  },
  decode(str) {
    return hashids.decode(str) - 111123441122342
  },
  USDOrCNY(str) {
    const USD = ['usd', 'cad', 'aud']
    if (USD.includes(str?.toString())) {
      return '$'
    } else {
      return '¥'
    }
  }
}

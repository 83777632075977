/*
 * @Description: pdf
 * @Author: shilin deng
 * @Date: 2021-04-12 15:50:00
 */
/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import Script from 'react-load-script'

export default function PDF({ file = null, name = 'doc.pdf', set }) {
  useEffect(() => {
    // https://documentcloud.adobe.com/view-sdk-demo/index.html#/view/FULL_WINDOW/Bodea%20Brochure.pdf   demo
    // https://www.adobe.io/apis/documentcloud/dcsdk/pdf-embed.html
    // https://console.adobe.io/projects/535032/4566206088344636953/apis/ViewSDK/details
    // https://developer.adobe.com/console/home 配置域名
    if (window.AdobeDC) {
      newPreviewFile()
    } else {
      document.addEventListener('adobe_dc_view_sdk.ready', function () {
        newPreviewFile()
      })
    }
  }, [])
  function newPreviewFile() {
    /* Control the default view mode */
    const viewerConfig = {
      defaultViewMode: 'FIT_WIDTH',
      // embedMode: 'SIZED_CONTAINER',
      // embedMode: 'IN_LINE',
      // embedMode: 'LIGHT_BOX',
      showAnnotationTools: false,
      dockPageControls: false,
      enablePDFAnalytics: true,
      showLeftHandPanel: true,
      showPageControls: true,
      ...set,
    }
    let id
    const hostname = window.location.hostname
    if (hostname.includes('localhost')) {
      id = '1f445214eeed40c68784d3e5ec8e9e49'
    } else if (hostname.includes('www.quanchengvip.com')) {
      id = 'cbdac337e4754886b90c74144bd36690'
    }else if (hostname.includes('pro.quanchengvip.com')) {
      id = 'cbdac337e4754886b90c74144bd36690'
    } else if (hostname.includes('stg.quanchengvip.com')) {
      id = '937da449b4514254943a84328587079a'
    } else if (hostname.includes('feature.quanchengvip.com')) {
      id = '4fbbb005cd5748c398a8c603f394c2e6'
    }
    var adobeDCView = new AdobeDC.View({
      clientId: id,
      divId: 'pdf-view',
    })
    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: file,
          },
        },
        metaData: { fileName: name.includes('.pdf') ? name : `${name}.pdf` },
      },
      viewerConfig
    )
  }
  return (
    <div>
      <Script
        type="text/javascript"
        url="https://documentservices.adobe.com/view-sdk/viewer.js"
        charset="utf-8"
      />
      <div id="pdf-view" className="easyke_doc" />
    </div>
  )
}

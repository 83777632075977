/*
 * @Description: 多选
 * @Author: shilin deng
 * @Date: 2021-07-29 10:53:39
 */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Select } from 'antd'

export default function SelectMultiple(props) {
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = 'formErrorRightTop'
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (props.error) {
    switch (props.positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    errorContentField = <div className="formErrorContent">{props.error}</div>
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map(
      (option) => <div key={option} className={`line${option}`} />,
    )
  }
  const selectProps = {
    mode: 'multiple',
    style: { width: '100%', maxHeight: 500 },
    value: props.value,
    options: props.options,
    onChange: props.onChange,
    dropdownStyle: { maxHeight: 500 },
    placeholder: props.placeholder,
    // maxTagCount: 'responsive',
  }

  return (
    <div>
      <div className="c-form-control">
        <Select {...selectProps} />
        <div
          className={`form-validation-field-0formError parentFormundefined formError greenPopup ${position}`}
        >
          {!errorPositionIsBottom && errorContentField}
          <div className="formErrorArrow">{errorArrowField}</div>
          {errorPositionIsBottom && errorContentField}
        </div>
      </div>
    </div>
  )
}

/**
 *@Description modal
 *@author meng wang
 *@date 2020/7/24
 */
import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Icon from './icon'

export default function Modal({
  close,
  width,
  hidden,
  footer,
  children,
  className,
  textCl = false,
  style,
  bodyCl,
  zIndex,
  hideClose = false,
}) {
  const [container, setContainer] = useState()
  useEffect(() => {
    if (hidden !== 'hidden') {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = ''
    }
    return () => {
      document.body.style.position = 'static'
    }
  })
  useEffect(() => {
    const rootContainer = document.createElement('div')
    const parentElem = document.querySelector('#__next')
    if (typeof window !== 'undefined') {
      if (!container) {
        parentElem.appendChild(rootContainer)
        setContainer(rootContainer)
      }
    }
    return () => {
      parentElem.removeChild(rootContainer)
    }
  }, [])
  const modal = (
    <div className="c-modal modal fade" hidden={hidden} style={{ ...zIndex }}>
      <div
        className={`c-modal__content c-modal__content--s ${className}`}
        style={{ ...style, width: width || 'auto' }}
      >
        <div className="c-modal__header">
          <a
            href="#"
            className="js-popup-close gray"
            data-dismiss="modal"
            onClick={close}
          >
            {!hideClose && <Icon name="x" />}
          </a>
        </div>

        <div
          className={`c-modal__body ${textCl ? undefined : 'u-text-center'}`}
          style={bodyCl}
        >
          {children}
        </div>
        {footer && <div className="c-modal__footer">{footer}</div>}
      </div>
    </div>
  )
  return container ? ReactDOM.createPortal(modal, container) : null
}

/* eslint-disable consistent-return */
/**
 *@Description react dropdown
 *@author meng wang
 *@date 2020/7/30
 */
import React, { useState } from 'react'
import bem from '../../utils/bem'

export default function Dropdown({ className, children }) {
  const [flag, setFlag] = useState(false)
  function toggleDropdown(e, active) {
    e.target
      .closest('.js-dropdown')
      .querySelector('.js-dropdown__popup')
      .classList.toggle('is-active', active)
  }
  function mobileClick(e) {
    if (bem.pc_or_mobile()) {
      toggleDropdown(e, !flag)
      setFlag(!flag)
      return false
    }
  }
  return (
    <div className={className}>
      {React.Children.map(children, (child) => React.cloneElement(child, {
        onMouseEnter: (e) => toggleDropdown(e, true),
        onMouseLeave: (e) => toggleDropdown(e, false),
        onPointerDown: (e) => mobileClick(e),
      }))}
    </div>
  )
}

/* eslint-disable no-script-url */
/* eslint-disable no-debugger */
/* eslint-disable*/
// tutor editor
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useAlert } from 'react-alert'
import Icon from './icon'
import Btn from './btn'
import Tag from './qa/tag'
import { Checkbox } from 'antd'
import Preview from './Preview'
import bem from '../../utils/bem'

export default function TinymceEditor({
  init = {},
  initialValue = '',
  fileList,
  setFileList,
  text,
  handleSubmit,
  nodeCheck,
  id,
  fileHide,
  data,
  onEditorChange,
  btnHide,
}) {
  const [show, setShow] = useState(false)
  const alert = useAlert()
  const label = `<label style="position: absolute; top: 5px; left: 0px; color: #9b9b9b; padding: 1%; width: 98%; overflow: hidden; white-space: pre-wrap; font-size: 18px; -webkit-font-smoothing: antialiased">${initialValue}</label>`
  const [value, setValue] = useState()
  const [check, setCheck] = useState()
  const [mobile, setMobile] = useState(false)
  
  const defaultStyle = {
    width: 'auto'
  }
  useEffect(() => {
    setMobile(bem.pc_or_mobile)
  }, [1])
  const initParams = _.merge(
    {
      menubar: false,
      statusbar: true,
      branding: false,
      autoresize_on_init: true,
      min_height: 450,
      plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table directionality paste',
        'emoticons template paste textcolor colorpicker textpattern imagetools tiny_mce_wiris mathjax',
      ],

      external_plugins: {
        tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
        mathjax:
          'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/tinymce-mathjax/plugin.min.js',
      },
      mathjax: {
        enable: true,
        lib: 'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/mathjax/tex-mml-svg.js',
        className: 'math-tex', //optional: mathjax element class
        configUrl:
          'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/tinymce-mathjax/config.js',
      },
      relative_urls: false,
      toolbar1:
        'insertfile undo redo | styleselect | fontsizeselect bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | preview | forecolor backcolor | tiny_mce_wiris_formulaEditor wordcount mathjax',

      paste_data_images: true,
      image_title: true,
      file_picker_types: 'image',
      media_live_embeds: true,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.onchange = function () {
          var file = this.files[0]

          var reader = new FileReader()
          reader.onload = function () {
            var id = 'blobid' + new Date().getTime()
            var blobCache = tinymce.activeEditor.editorUpload.blobCache
            var base64 = reader.result.split(',')[1]
            var blobInfo = blobCache.create(id, file, base64)
            blobCache.add(blobInfo)
            cb(blobInfo.blobUri(), { title: file.name })
          }
          reader.readAsDataURL(file)
        }

        input.click()
      },
    },

    init
  )

  // 控制placeholder
  function controlPlaceholder(iframe, type) {
    const tinymce = iframe.contentWindow.document.getElementById('tinymce')
    if (
      type &&
      tinymce.innerText.trim() === initialValue &&
      !tinymce.getElementsByTagName('img').length
    ) {
      setValue('')
    } else if (
      !type &&
      !(tinymce.innerText.trim() || tinymce.getElementsByTagName('img').length)
    ) {
      setValue(label)
    }
  }

  useEffect(() => {
    if (!initialValue) {
      return
    }
    let timer = null
    function check() {
      const iframe = document.getElementById('tiny-editor_ifr')
      if (iframe) {
        if (document.activeElement.id === 'tiny-editor_ifr') {
          setValue('')
        }
        iframe.contentWindow.addEventListener('focus', () =>
          controlPlaceholder(iframe, true)
        )
        iframe.contentWindow.addEventListener('blur', () =>
          controlPlaceholder(iframe, false)
        )
        if (timer) {
          clearTimeout(timer)
        }
      } else {
        timer = setTimeout(check, 0)
      }
    }
    check()
  }, [])

  // 点击事件映射到input
  function uploadFile() {
    document.getElementById('qa_question_question_type').click()
  }

  // 选择文件
  function fileChoose(e) {
    if (!e.target.files.length) {
      return
    }
    if (
      !fileList.length ||
      fileList.every((file) => file.name !== e.target.files[0].name)
    ) {
      if (e.target.files[0].size === 0) {
        alert.error('文件没有内容，请检查文档后重新上传')
        return
      }
      setFileList(fileList.concat(e.target.files[0]))
    }
    e.target.value = null
  }

  // 移除文件
  function removeFile(name) {
    setFileList(fileList.filter((file) => file.name !== name))
  }

  // 提交
  function submit(e) {
    if (text === 'Loading') {
      return
    }
    e.preventDefault()
    const matchReg = /(<math(?:(?!<\/?math>)[\s\S])*<\/math>)/g
    if (
      !data
        .replace(matchReg, '')
        .replace(/<p>[\r\n\s&nbsp;]*<\/p>/g, '')
        .replace(/<img.*?(?:>|\/>)/gi, '')
        .trim() ||
      data
        .replace(matchReg, '')
        .replace(/<p>[\r\n\s&nbsp;]*<\/p>/g, '')
        .replace(/<img.*?(?:>|\/>)/gi, '')
        .trim() === '<p></p>'
    ) {
      alert.error('Please enter at least 1 words')
      return
    }
    if (nodeCheck) {
      handleSubmit({ check: check })
    } else {
      handleSubmit()
    }
  }
  return (
    <div className="c-text-editor__inner">
      <div className="tinymce_editor_reset">
        <Editor
          id={id ? id : 'tiny-editor'}
          tinymceScriptSrc={`https://cdn.tiny.cloud/1/${process.env.NEXT_PUBLIC_TINYMCE_API_KEY}/tinymce/5/tinymce.min.js`}
          apiKey={process.env.NEXT_PUBLIC_TINYMCE_API_KEY}
          cloudChannel="dev"
          value={data}
          init={initParams}
          onEditorChange={onEditorChange}
        />
      </div>

      <div className="form_footer">
        {!fileHide && (
          <div className="attachments_list">
            <div className="names_list">
              {fileList.length
                ? fileList.map((file) => (
                    <Tag
                      name={file.name}
                      removeTag={removeFile}
                      key={file.name}
                    />
                  ))
                : null}
            </div>
          </div>
        )}

        <div
          className={`mt1 l-row  u-align-items-end ${
            fileHide ? 'u-justify-content-end' : 'u-justify-content-between'
          }`}
        >
          {!fileHide && (
            <div className="l-col-md">
              <a className="attach_file js-attach" onClick={uploadFile}>
                <Icon name="paperclip" size="square-xs" cl="u-color-dark-dk" />
                <div className="c-form-label c-form-label--inline u-color-dark u-font-semibold u-font-size-s">
                  上传附件
                </div>
              </a>
              <input
                type="file"
                hidden="hidden"
                name="qa_question"
                onChange={fileChoose}
                id="qa_question_question_type"
              />
            </div>
          )}

          {nodeCheck && (
            <div className="l-col-md-auto">
              <div className="u-mr-m u-d-inline-block">
                <Checkbox
                  checked={check}
                  onChange={(e) => setCheck(e.target.checked)}
                >
                  {' '}
                  {nodeCheck.text}
                </Checkbox>
              </div>
            </div>
          )}
          
          <div className={`l-col-md-auto`} style={mobile ? defaultStyle : null}>
              <Btn
                href="#"
                target=""
                onClick={() => setShow(true)}
                cl="submit_question"
                content="Preview"
              />
          </div>
          <div className={`l-col-md-auto`} style={mobile ? defaultStyle : null}>
            {!btnHide && (
              <Btn
                href="#"
                target=""
                disabled={text === 'Loading'}
                onClick={submit}
                cl="submit_question"
                content={text}
              />
            )}
          </div>
          
        </div>
      </div>
      {show && (
        <Preview close={() => setShow(false)} id={id ? id : 'tiny-editor'} />
      )}
      <br />
    </div>
  )
}

/*
 * @Description:textarea
 * @Author: shilin deng
 * @Date: 2021-07-02 17:10:18
 */
/* eslint-disable  react/jsx-props-no-spreading */

import React from 'react'

export default function Textarea({
  error,
  textareaClass,
  positionType,
  lg,
  ...rest
}) {
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = 'formErrorRightTop'
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (error) {
    switch (positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    errorContentField = <div className="formErrorContent">{error}</div>
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map(
      (option) => <div key={option} className={`line${option}`} />,
    )
  }
  return (
    <div className={textareaClass}>
      <div className="c-form-control">
        <textarea
          {...rest}
          rows={3}
          className={`c-form-control__field c-form-field c-form-field--primary ${
            lg ? '' : 'c-form-field--lg'
          }`}
        />
        <div
          className={`form-validation-field-0formError parentFormundefined formError greenPopup ${position}`}
        >
          {!errorPositionIsBottom && errorContentField}
          <div className="formErrorArrow">{errorArrowField}</div>
          {errorPositionIsBottom && errorContentField}
        </div>
      </div>
    </div>
  )
}

/**
 *@Description 选择框输入框组合
 *@author meng wang
 *@date 2020/7/24
 */
import React from 'react'
import Select from './select'
import Input from './input'

export default function SelectTextGroup({options, selectProps, inputProps}) {
  return (
    <div className="c-form-control">
      <div className="l-row no-gutters">
        <div className="l-col-4 js-user_login_country_selector c-form-select c-form-select--lg">
          <Select {...selectProps} options={options} className="chosen-container chosen-container-single" />
        </div>
        <div className="l-col-8">
          <Input
            {...inputProps}
            className="c-form-control__field c-form-field c-form-field--primary c-form-field--lg"
          />
        </div>
      </div>
    </div>
  )
}

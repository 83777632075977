/**
 *@Description select position
 *@author meng wang
 *@date 2020/8/6
 */
import { useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function Position(props) {
  let instance
  const { targetRef, isInput, zIndex, children } = props
  if (!instance) {
    instance = document.createElement('div')
    instance.className = `ll-position chosen-container chosen-container-single chosen-container-active ${
      isInput && 'll-position--max-height'
    }`
    instance.style.zIndex = zIndex
    instance.style.borderRadius = '3px'
    instance.style.boxShadow = '0 2px 20px 0 rgba(0,0,0,0.08)'
    if (document.getElementsByClassName('c-modal').length) {
      instance.style.zIndex = 100001
    }
    document.getElementById('__next').appendChild(instance)
  }

  useEffect(() => {
    function setInstanceStyle() {
      if (!targetRef.current) {
        return null
      }
      const { top, left, height, width } = targetRef.current?.getBoundingClientRect()
      const style = {
        top: `${
          document.documentElement.scrollTop + top + (isInput ? 16 : 0) + height
        }px`,
        left: `${document.documentElement.scrollLeft + left}px`,
        width: `${width}px`,
      }

      instance.style.top = style.top
      instance.style.left = style.left
      instance.style.width = style.width

      return { top, height }
    }

    setInstanceStyle()
    window.addEventListener('resize', setInstanceStyle, false)
    window.addEventListener('scroll', setInstanceStyle, true)

    return () => {
      window.removeEventListener('resize', setInstanceStyle, false)
      window.removeEventListener('scroll', setInstanceStyle, true)
      document.getElementById('__next').removeChild(instance)
    }
  })

  return instance && ReactDOM.createPortal(children, instance)
}

/* eslint-disable no-script-url */
/* eslint-disable no-debugger */
/* eslint-disable*/
/**
 *@Description editor
 *@author meng wang
 *@date 2020/8/13
 */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useAlert } from 'react-alert'
import Icon from './icon'
import Btn from './btn'
import Tag from './qa/tag'
import { Checkbox } from 'antd'

export default function TinymceEditor({
  handleEditorChange,
  init = {},
  initialValue = '',
  fileList,
  setFileList,
  text,
  handleSubmit,
  nodeCheck,
  id,
  fileHide,
  type,
}) {
  const alert = useAlert()
  const label = `<label style="position: absolute; top: 5px; left: 0px; color: #9b9b9b; padding: 1%; width: 98%; overflow: hidden; white-space: pre-wrap; font-size: 18px; -webkit-font-smoothing: antialiased">${initialValue}</label>`
  const [value, setValue] = useState(label)
  const [fileProps, setFileProps] = useState({})
  const initParams = _.merge(
    {
      menubar: false,
      statusbar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools tiny_mce_wiris mathjax',
      ],
      external_plugins: {
        tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
        mathjax:
          'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/tinymce-mathjax/plugin.min.js',
      },
      mathjax: {
        enable: true,
        lib: 'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/mathjax/tex-mml-svg.js',
        className: 'math-tex', //optional: mathjax element class
        configUrl:
          'https://ek-nextjs.s3.ap-northeast-2.amazonaws.com/js/tinymce-mathjax/config.js',
      },
      relative_urls: false,
      toolbar1: 'bold italic numlist link tiny_mce_wiris_formulaEditor',
      icons: {
        selector: '#link',
        icons_url: 'https://feature.easyke.com/images/footer/wechat.svg', // load icon pack
        icons: 'custom'
      }
    },
    init
  )

  // 控制placeholder
  function controlPlaceholder(iframe, type) {
    const tinymce = iframe.contentWindow.document.getElementById('tinymce')
    if (
      type &&
      tinymce.innerText.trim() === initialValue &&
      !tinymce.getElementsByTagName('img').length
    ) {
      setValue('')
    } else if (
      !type &&
      !(tinymce.innerText.trim() || tinymce.getElementsByTagName('img').length)
    ) {
      setValue(label)
    }
  }

  useEffect(() => {
    if (!initialValue) {
      return
    }
    let timer = null
    function check() {
      const iframe = document.getElementById('tiny-editor_ifr')
      if (iframe) {
        if (document.activeElement.id === 'tiny-editor_ifr') {
          setValue('')
        }
        iframe.contentWindow.addEventListener('focus', () =>
          controlPlaceholder(iframe, true)
        )
        iframe.contentWindow.addEventListener('blur', () =>
          controlPlaceholder(iframe, false)
        )
        if (timer) {
          clearTimeout(timer)
        }
      } else {
        timer = setTimeout(check, 0)
      }
    }
    check()
  }, [])

  function editChange(code) {
    const reg = new RegExp(`<p>${label}</p>$`)
    if (document.activeElement.id === 'tiny-editor_ifr' && reg.test(code)) {
      setValue('')
    } else {
      setValue(code)
    }
    handleEditorChange(reg.test(code) ? '' : code)
  }
  useEffect(() => {
    if (type === 'essayhelp') {
      setFileProps({accept:'.doc,.docx'})
    } else {
      setFileProps({})
    }
  },[])
  // 点击事件映射到input
  function uploadFile() {
    document.getElementById('qa_question_question_type').click()
  }

  // 选择文件
  function fileChoose(e) {
    if (!e.target.files.length) {
      return
    }
    if (type === 'essayhelp') {
      if (!(['doc','docx'].includes(e.target.files[0].name.split('.').pop()))){
        alert.error('请注意论文初稿必须为word格式')
        return
      }
    }
    if (
      !fileList.length ||
      fileList.every((file) => file.name !== e.target.files[0].name)
    ) {
      if (e.target.files[0].size === 0) {
        alert.error('文件没有内容，请检查文档后重新上传')
        return
      }
      setFileList(fileList.concat(e.target.files[0]))
    }
    e.target.value = null
  }

  // 移除文件
  function removeFile(name) {
    setFileList(fileList.filter((file) => file.name !== name))
  }

  // 提交
  function submit(e) {
    if (text === '请稍候') {
      return
    }
    e.preventDefault()
    const matchReg = /(<math(?:(?!<\/?math>)[\s\S])*<\/math>)/g
    if (
      !value
        .replace(matchReg, '')
        .replace(/<p>[\r\n\s&nbsp;]*<\/p>/g, '')
        .trim()
    ) {
      alert.error('问题长度太短')
      return
    }
    handleSubmit()
  }
  return (
    <div className="c-text-editor__inner">
      <div className="tinymce_editor_reset">
        <Editor
          id={id ? id : 'tiny-editor'}
          tinymceScriptSrc={`https://cdn.tiny.cloud/1/${process.env.NEXT_PUBLIC_TINYMCE_API_KEY}/tinymce/5/tinymce.min.js`}
          apiKey={process.env.NEXT_PUBLIC_TINYMCE_API_KEY}
          cloudChannel="dev"
          value={value}
          init={initParams}
          onEditorChange={editChange}
        />
      </div>

      <div className="form_footer">
        {!fileHide && (
          <div className="attachments_list">
            <div className="names_list">
              {fileList.length
                ? fileList.map((file) => (
                    <Tag
                      name={file.name}
                      removeTag={removeFile}
                      key={file.name}
                    />
                  ))
                : null}
            </div>
          </div>
        )}

        <div className={`mt1 l-row  u-align-items-end ${fileHide?'u-justify-content-end':'u-justify-content-between'}`}>
          {!fileHide && (
            <div className="l-col-md">
              <a className="attach_file js-attach" onClick={uploadFile}>
                <Icon name="paperclip" size="square-xs" cl="u-color-dark-dk" />
                <div className="c-form-label c-form-label--inline u-color-dark u-font-semibold u-font-size-s">
                  上传附件
                </div>
              </a>
              <input
                type="file"
                hidden="hidden"
                name="qa_question"
                onChange={fileChoose}
                id="qa_question_question_type"
                {...fileProps}
              />
            </div>
          )}

          {nodeCheck && (
            <div className="l-col-md-auto">
              <div className="u-mr-m u-d-inline-block">
                <Checkbox checked={true}> {nodeCheck.text}</Checkbox>
              </div>
            </div>
          )}
          <div className={`l-col-md-auto `}>
            <Btn
              href="#"
              target=""
              disabled={text === '请稍候'}
              onClick={submit}
              cl="submit_question"
              content={text}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable camelcase, no-unused-vars */
/**
 *@Description question
 *@author meng wang
 *@date 2020/8/20
 */
import React from 'react'
import bem from '../../utils/bem'
import { QUESTION_TYPES } from '../../utils/constant'

function Question({
  onClick, code, subjects, easyke_mentor_id, easyke_mentor_name,
  created_at, unread_comment, content_string, specializations, question_type,
  essay_options, status, rating, ...rest
}) {
  return (
    <a className="c-ques c-ques--shadow js-question c-ques--border" onClick={onClick}>
      <ul className="c-ques__info">
        {code && (
          <li className="c-ques__info__item c-ques__info__item--mdot js-question_subject">
            <span className="u-font-semibold u-color-primary-dk">
              {code}
            </span>
          </li>
        )}
        {subjects && (
          <li className="c-ques__info__item c-ques__info__item--mdot js-question_subject">
            <span className="u-font-semibold u-color-primary-dk">
              {subjects[0]}
            </span>
          </li>
        )}
        {easyke_mentor_id && easyke_mentor_name && (
          <li className="c-ques__info__item c-ques__info__item--mdot js-question_date">
            {easyke_mentor_id}
            -
            {easyke_mentor_name}
          </li>
        )}
        {created_at && (
          <li className="c-ques__info__item c-ques__info__item--mdot js-question_date">
            {bem.formatDate(created_at)}
          </li>
        )}
      </ul>
      <div className="c-ques__title">
        <p className="c-ques__title--line-1">{content_string}</p>
        {unread_comment && <div className="c-message-counter c-message-counter--empty--sm c-ques__counter c-ques__counter__z-index-0" />}
      </div>
      <div className="question_tag">
        {
          specializations?.length ? <div className="c-tag c-tag--alt" key={specializations[0]}>{specializations[0]}</div> : null
        }
      </div>
      <div className="question_tag">
        {
          question_type === QUESTION_TYPES.essay && (
            <>
              <div className="c-tag c-tag--alt" key="Essay">Essay</div>
              &nbsp;
            </>
          )
        }
        {
          essay_options?.length ? <div className="c-tag c-tag--alt" key={essay_options[0]}>{essay_options[0]}</div> : null
        }
      </div>
      {status === 'answered' && (
        <>
          <hr className="u-my-s" />
          <ul className="c-ques__info">
            <li className="c-ques__info__item c-ques__info__item--vbar answered_tag u-font-semibold u-color-primary">
              已解答
            </li>
          </ul>
        </>
      )}
      {status === 'closed' && !rating && (
        <>
          <hr className="u-my-s" />
          <ul className="c-ques__info">
            <li className="c-ques__info__item c-ques__info__item--vbar answered_tag u-font-semibold u-color-primary">
              未评分
            </li>
          </ul>
        </>
      )}
    </a>
  )
}

export default React.forwardRef((props, ref) => Question(props, ref))

/*
 * @Description: 电话输入
 * @Author: shilin deng
 * @Date: 2021-06-23 09:40:45
 */
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

export default function TelInput({
  error,
  positionType,
  onChange,
  value,
  onBlur,
  ...res
}) {
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = 'formErrorRightTop'
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (error) {
    switch (positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    errorContentField = <div className="formErrorContent">{error}</div>
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map(
      (option) => <div key={option} className={`line${option}`} />,
    )
  }

  return (
    <div className="telinput">
      <PhoneInput {...res} value={value} onChange={onChange} onBlur={onBlur} />
      <div
        className={`form-validation-field-0formError parentFormundefined formError greenPopup ${position}`}
      >
        {!errorPositionIsBottom && errorContentField}
        <div className="formErrorArrow">{errorArrowField}</div>
        {errorPositionIsBottom && errorContentField}
      </div>
    </div>
  )
}

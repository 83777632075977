/**
 *@Description avatar
 *@author meng wang
 *@date 2020/8/19
 */
import React, { useState, useEffect } from 'react'
import Icon from './icon'

export default function Avatar({src, errorImg, username, schoolName, description, icon}) {
  const [imgSrc, setImgSrc] = useState(src)
  useEffect(() => {
    setImgSrc(src)
  }, [src])
  return (
    <>
      <div className="u-text-center">
        <div className="p-qa__avatar">
          {icon && <Icon {...icon} name="/images/icons/mentor-badge.svg" type="oc" cl="p-qa__avatar__badge no-stroke" />}
          <div className="c-avatar c-avatar--xl">
            <img
              onError={() => setImgSrc(errorImg || '/images/avatar/small.png')}
              className="c-avatar__img mentor_image"
              src={imgSrc}
              alt=""
            />
          </div>
        </div>
        <h6 className="u-mb-0">{username}</h6>
        <p className="u-color-dark u-font-size-xs">{schoolName}</p>
      </div>
      <div className="o-block o-block--lt u-p-s">
        <p className="u-color-dark u-mb-0">{description}</p>
      </div>
    </>
  )
}

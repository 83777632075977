/**
 *@Description steps
 *@author meng wang
 *@date 2021/1/6
 */

export default function Steps({isActive, flex = false, wrapperClass}) {
  const STEP_ITEMS = [
    {count: '1', label: '个人信息', isActive: isActive === 1},
    {count: '2', label: '留学意向', isActive: isActive === 2},
    {count: '3', label: '确认信息', isActive: isActive === 3},
  ]
  return (
    <div className={`c-steps ${flex && 'c-steps--flex'} ${wrapperClass}`}>
      {
        STEP_ITEMS.map((item) => (
          <div key={item.label} className={`c-steps__item ${item.isActive && 'is-active'}`}>
            <div className="c-steps__item__count">
              {item.count || ''}
            </div>
            <div className="c-steps__item__label">
              {item.label || ''}
            </div>
          </div>
        ))
      }
    </div>
  )
}

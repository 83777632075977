/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable */

/**
 *@Description select position
 *@author meng wang
 *@date 2020/8/6
 */
import React, { useState,useEffect } from 'react'

export default function SelectMenu (props) {
  const [selected, setSelected] = useState(-1)
  const { options, classNames = '', prompts = null, cl = undefined ,menuClassName} = props

  useEffect(() => {
    if (options && options.length > 0) {
      let arr = options.map(v => v[0]);
    let i = arr.indexOf(props.value)
    setSelected(i)
    }
    
  },[])
  function selectChange (name, label, value, index) {
    setSelected(index)
    if (props.setValue) {
      props.setValue(name, value)
    }
    if (props.onChange) {
      props.onChange(label, value)
    }
  }

  return (
    <ul className={`chosen-results ${menuClassName}`} style={cl}>
      {prompts && (
        <li
          className={`active-result ${classNames}`}
          key={prompts}
          // onClick={() => selectChange(props.name, prompts)}
        >
          {prompts}
        </li>
      )}
      {
        options && options.map((option, index) => {
          const [label, value] = option
          return (
            <li
              key={value}
              onClick={() => selectChange(props.name, label, value, index)}
              onBlur={props.onBlur}
              // onMouseOver={() => setSelected(index)}
              // onMouseOut={() => setSelected(-1)}
              className={`${classNames} active-result ${selected === index ? 'highlighted' : ''}`}
            >
              {label}
            </li>
          )
        })
      }
    </ul>
  )
}

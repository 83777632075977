/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable  no-script-url */
/**
 *@Description select
 *@author meng wang
 *@date 2020/7/29
 */
import React, { useState, useEffect, useRef } from 'react'
import Position from './position'
import SelectMenu from './select-menu'

export default function Select({
  name,
  value,
  className = '',
  zIndex,
  search,
  width,
  options,
  prompts,
  onChange,
  onBlur,
  setFieldValue,
  inputField,
  cl,
  textsl = undefined,
  error,
  positionType,
}) {
  const aRef = useRef(null)
  const inputRef = useRef(null)
  // 控制下拉框显示/隐藏
  const [visible, setVisible] = useState(false)
  let isOnComposition

  function bindBodyClick(e) {
    if (e.target.id === 'searchInput') return
    if (aRef.current.contains(e.target)) {
      setVisible(!visible)
    } else {
      setVisible(false)
    }
  }
  const line = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let position = 'formErrorRightTop'
  let errorPositionIsBottom
  let errorArrowField
  let errorContentField
  if (error) {
    switch (positionType) {
      case 'rightTop':
        position = 'formErrorRightTop'
        break
      case 'leftBottom':
        position = 'formErrorLeftBottom'
        break
      case 'rightBottom':
        position = 'formErrorRightBottom'
        break
      default:
    }
    errorPositionIsBottom = position.includes('Bottom')
    errorContentField = (
      <div
        className="formErrorContent"
        dangerouslySetInnerHTML={{ __html: error }}
      />
    )
    errorArrowField = (errorPositionIsBottom ? line : line.reverse()).map(
      (option) => <div className={`line${option}`} />,
    )
  }
  useEffect(() => {
    document.addEventListener('click', bindBodyClick, false)
    return () => {
      document.removeEventListener('click', bindBodyClick, false)
    }
  }, [visible])

  const [optionNow, setOptionNow] = useState(options)
  useEffect(() => {
    setOptionNow(options)
  }, [options])

  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [visible, searchValue])
  function handleChange(event) {
    if (!isOnComposition) {
      setOptionNow(
        options.filter((option) => option[0].toLowerCase().includes(event.target.value.toLowerCase())),
      )
      setSearchValue(event.target.value)
    }
  }
  function handleComposition(event) {
    if (event.type === 'compositionend') {
      isOnComposition = false
      handleChange(event)
    } else {
      isOnComposition = true
    }
  }

  return (
    <div className={`c-navbar__dd__toggle ${className}`}>
      <a
        name={name}
        className="chosen-single"
        ref={aRef}
        onClick={() => setVisible(true)}
      >
        {inputField ? (
          <input {...inputField} />
        ) : (
          <>
            <span style={textsl}>{value || prompts || '请选择'}</span>
            <div>
              <b />
            </div>
          </>
        )}
      </a>
      {visible ? (
        <Position targetRef={aRef} isInput={inputField} zIndex={zIndex}>
          <div className="chosen-search">
            {search && (
              <input
                ref={inputRef}
                id="searchInput"
                defaultValue={searchValue}
                onCompositionStart={handleComposition}
                onCompositionUpdate={handleComposition}
                onCompositionEnd={handleComposition}
                onChange={handleChange}
                style={{ width }}
                className="chosen-search-input"
                // autoFocus="autoFocus"
              />
            )}
          </div>
          <SelectMenu
            onChange={onChange}
            onBlur={onBlur}
            setValue={setFieldValue}
            name={name}
            options={optionNow}
            prompts={prompts}
            cl={cl}
            value={value}
          />
        </Position>
      ) : null}
      <div
        className={`form-validation-field-0formError parentFormundefined formError greenPopup ${position}`}
      >
        {!errorPositionIsBottom && errorContentField}
        <div className="formErrorArrow">{errorArrowField}</div>
        {errorPositionIsBottom && errorContentField}
      </div>
    </div>
  )
}

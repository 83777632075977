/**
 *@Description tag with remove
 *@author meng wang
 *@date 2020/8/20
 */
import React from 'react'
import Icon from '../icon'

export default function Tabs({name, type = 'primary', removeTag, ifRemove = true, cl}) {
  return (
    <div className={`c-tag c-tag--${type} c-tag--md c-tag--with-close attachment_name label-attachment ${cl || ''}`}>
      {name}
      {ifRemove && (
        <span role="none" className="pointer" onClick={() => removeTag(name)} onKeyDown={() => removeTag(name)}>
          &nbsp;
          <Icon name="x" size="square-xxs" />
        </span>
      )}
    </div>
  )
}
